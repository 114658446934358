import * as PIXI from "pixi.js";
import { Group } from "../../Graphics/Group";
import { Logic, _s, settings, _t } from "../../Logic/Logic";
import { IColors, IDriver, IAnimInterval } from "../../Logic/LogicDefinitions";
import { AnimHelper } from "../common/Anim";
import { GameLength } from "../../common/Definitions";

export class OddsScreenKart extends Group {
  private oddsHeaderVicente: PIXI.Text;
  private oddsHeaderAccopiata: PIXI.Text;
  private oddsHeaderVicenteAnims: IAnimInterval[] = [];
  private oddsHeaderAccopiataAnims: IAnimInterval[] = [];
  private oddsTexts: PIXI.Text[] = [];
  private racers: IDriver[] = [];
  private first: PIXI.Text;
  private second: PIXI.Text;
  private anims: IAnimInterval[] = [];
  private gameLength: GameLength;
  private language: string;

  public constructor(racerCount: number, gameLength: GameLength, language: string) {
    super();

    this.gameLength = gameLength;
    this.language = language;

    this.showDebug(settings.debug);

    {
      for (let iRow = 0; iRow < racerCount; iRow++) {
        for (let iCol = 0; iCol < racerCount; iCol++) {
          const text = Logic.createPixiText();
          text.position.x = _s(145 + iCol * 148);
          text.position.y = _s(90 + iRow * 46.5);
          text.anchor.set(0.5);
          this.oddsTexts.push(text);
          this.add(text);
        }
      }
    }

    const stylePos = new PIXI.TextStyle({
      fontFamily: "DIN-Light",
      fontSize: _s(13),
      letterSpacing: _s(8),
      fill: "#AAAAAA",
      align: "center"
    });
    this.first = Logic.createPixiText(stylePos);
    this.first.anchor.set(0.5, 0.5);
    this.add(this.first);

    this.second = Logic.createPixiText(stylePos);
    this.second.anchor.set(0.5, 0.5);
    this.add(this.second);

    {
      const style = new PIXI.TextStyle({
        fontFamily: "DIN-Regular",
        fontSize: _s(12),
        letterSpacing: _s(2),
        fill: "white",
        align: "center"
      });
      this.oddsHeaderVicente = Logic.createPixiText(style);
      this.oddsHeaderVicente.anchor.set(0.5, 1.0);

      this.oddsHeaderAccopiata = Logic.createPixiText(style);
      this.oddsHeaderAccopiata.anchor.set(0.5, 1.0);
      if (language === "it") {
        this.add(this.oddsHeaderVicente);
        this.add(this.oddsHeaderAccopiata);
      }
    }
  }

  public createOddsHeaderAnims(gameLength: GameLength, language: string): IAnimInterval[][] {
    if (language !== "it") return [];

    switch (gameLength) {
      case 300:
        return [
          [
            { startTime: 11.0, duration: 4.3 },
            { startTime: 100.1, duration: 6.0 },
            { startTime: 155.3, duration: 5.9 }
          ],
          [
            { startTime: 16.5, duration: 2.3 },
            { startTime: 107.5, duration: 3.0 },
            { startTime: 162.7, duration: 3.0 }
          ]
        ];
      default:
        return [
          [
            { startTime: 10.3, duration: 4.2 },
            { startTime: 90.35, duration: 5.7 },
            { startTime: 140.35, duration: 5.7 }
          ],
          [
            { startTime: 15.4, duration: 1.8 },
            { startTime: 97.5, duration: 2.9 },
            { startTime: 147.7, duration: 2.9 }
          ]
        ];
    }
  }

  public fill(racers: IDriver[], odds: number[], withBonus: boolean, colors: IColors) {
    this.racers = racers;
    const style = new PIXI.TextStyle({
      fontFamily: "DIN-Regular",
      fontSize: _s(22),
      fill: "white",
      align: "center"
    });
    const styleBold = new PIXI.TextStyle({
      fontFamily: "DIN-Heavy",
      fontSize: _s(22),
      fill: "white",
      align: "center"
    });

    const minMax = Logic.calcOddsMinMax(odds, racers.length);

    for (let iRow = 0; iRow < racers.length; iRow++) {
      for (let iCol = 0; iCol < racers.length; iCol++) {
        const val = Logic.getOddsForDriver(odds, iRow, iCol, racers.length);
        const oddsColor = Logic.getOddsColor(minMax, val, iRow, iCol);
        const text = this.oddsTexts[iCol + iRow * racers.length];
        text.text = Logic.implementation.formatOdds(val);
        text.style = oddsColor !== "white" ? styleBold : style;
        text.tint = colors[oddsColor];
      }
    }

    this.first.text = _t("first");
    this.second.text = _t("second");

    this.anims = this.createAnims(this.gameLength, withBonus, this.language);

    this.oddsHeaderVicente.text = _t("winner");
    this.oddsHeaderAccopiata.text = _t("forcastBet");
    this.first.text = _t("first").replace("_", "");
    this.second.text = _t("second").replace("_", "");
    const headerAnims = this.createOddsHeaderAnims(this.gameLength, this.language);
    if (headerAnims.length > 1) {
      this.oddsHeaderVicenteAnims = headerAnims[0];
      this.oddsHeaderAccopiataAnims = headerAnims[1];
    }
  }

  private createAnims(gameLength: GameLength, withBonus: boolean, language: string): IAnimInterval[] {
    switch (gameLength) {
      case 120:
        return [{ startTime: 0.1, duration: 12.7 }, withBonus ? { startTime: 35.1, duration: 13.7 } : { startTime: 30.0, duration: 19.0 }];
      case 180:
        return [withBonus ? { startTime: 0.1, duration: 18.1 } : { startTime: 0.1, duration: 22.7 }, withBonus ? { startTime: 90.0, duration: 18.8 } : { startTime: 90.0, duration: 18.8 }];
      case 240:
        return [
          language === "it" ? { startTime: 10.1, duration: 18.1 } : { startTime: 0.1, duration: 28.1 },
          { startTime: 90.1, duration: 28.1 },
          withBonus ? { startTime: 150.2, duration: 18.5 } : { startTime: 140.2, duration: 28.4 }
        ];
      case 300:
        return [
          language === "it" ? { startTime: 9.9, duration: 20.1 } : withBonus ? { startTime: 0.6, duration: 27.2 } : { startTime: 0.6, duration: 32.3 },
          withBonus ? { startTime: 90.3, duration: 27.9 } : { startTime: 100.1, duration: 28.1 },
          withBonus ? { startTime: 155.3, duration: 28.8 } : language === "it" ? { startTime: 155.5, duration: 29.0 } : { startTime: 155.5, duration: 29.0 }
          //withBonus ? { startTime: 160.2, duration: 23.9 } : { startTime: 164.3, duration: 20.1 },
        ];
    }
    return [];
  }

  public onLayout() {
    this.first.position.x = _s(12);
    this.first.rotation = -Math.PI * 0.5;
    this.first.position.y = _s(165);

    this.second.position.x = _s(440);
    this.second.position.y = _s(9);

    this.oddsHeaderVicente.position.x = _s(185);
    this.oddsHeaderVicente.position.y = _s(-2);
    this.oddsHeaderAccopiata.position.x = this.oddsHeaderVicente.position.x;
    this.oddsHeaderAccopiata.position.y = this.oddsHeaderVicente.position.y;
  }

  public update(dt: number) {
    super.update(dt);

    const t = Logic.getVideoTime();
    const anim = Logic.getAnim(t, this.anims, this);
    if (!anim) return;

    const racers = this.racers;
    let baseFactor = t - anim.startTime;
    if (baseFactor < anim.duration) {
      for (let iRow = 0; iRow < racers.length; iRow++) {
        const rowFactor = baseFactor - iRow * 0.1;
        for (let iCol = 0; iCol < racers.length; iCol++) {
          const index = iCol + iRow * racers.length;
          this.oddsTexts[index].alpha = rowFactor - iCol * 0.1;
        }
      }
      this.setDebugFade(AnimHelper.clamp(baseFactor));
      this.first.alpha = AnimHelper.clamp(baseFactor);
      this.second.alpha = this.first.alpha;
    } else {
      baseFactor = AnimHelper.limit(baseFactor, anim.duration);
      for (let iRow = 0; iRow < racers.length; iRow++) {
        const rowFactor = baseFactor + iRow * 0.1;
        for (let iCol = 0; iCol < racers.length; iCol++) {
          const index = iCol + iRow * racers.length;
          this.oddsTexts[index].alpha = rowFactor;
        }
      }
      this.setDebugFade(AnimHelper.clamp(baseFactor));
      this.first.alpha = AnimHelper.clamp(baseFactor);
      this.second.alpha = this.first.alpha;
    }

    const oddsHeaderVicenteAnim = Logic.getAnim(t, this.oddsHeaderVicenteAnims, this.oddsHeaderVicente);
    const oddsHeaderAccopiataAnim = Logic.getAnim(t, this.oddsHeaderAccopiataAnims, this.oddsHeaderAccopiata);
    if (oddsHeaderVicenteAnim)
      AnimHelper.animateInOut(t, oddsHeaderVicenteAnim.startTime, oddsHeaderVicenteAnim.startTime + oddsHeaderVicenteAnim.duration, 1, 0, 1, (alpha) => (this.oddsHeaderVicente.alpha = alpha), 0.2, 0);
    if (oddsHeaderAccopiataAnim)
      AnimHelper.animateInOut(
        t,
        oddsHeaderAccopiataAnim.startTime,
        oddsHeaderAccopiataAnim.startTime + oddsHeaderAccopiataAnim.duration,
        0.2,
        0,
        1,
        (alpha) => (this.oddsHeaderAccopiata.alpha = alpha),
        0.2,
        0
      );
  }
}

import * as PIXI from "pixi.js";
import { RouletteHelper } from "./RouletteHelper";
import { AnimHelper } from "./../common/Anim";
import { IAnimInterval } from "./../../Logic/LogicDefinitions";
import { Group } from "../../Graphics/Group";
import { Logic, settings, _s } from "../../Logic/Logic";
import { IGameInfo, IRoundInfo } from "../../Logic/LogicDefinitions";
import { StatOddNumber } from "./StatOddNumber";

export class AllNumberStats extends Group {
  private oddElements: StatOddNumber[] = [];
  private gameInfo: IGameInfo;
  private anims: IAnimInterval[] = [{ startTime: 0.0, duration: 0.0 }];

  public constructor(gameInfo: IGameInfo) {
    super();
    this.gameInfo = gameInfo;

    for (const number of RouletteHelper.allNumbers) {
      const nu = new StatOddNumber(gameInfo, number);
      this.oddElements.push(nu);
      this.add(nu);
    }
  }

  public onLayout(): void {
    this.oddElements.forEach((oddElement) => oddElement.onLayout());
    this.createGridLayout(this.oddElements);

    if (settings.debug) {
      this.oddElements.forEach((oddElement) => (oddElement.alpha = 0.6));
    }
  }

  public fill(roundInfo: IRoundInfo, rouletteStats: number[]): void {
    this.anims = this.createAnims();
    this.oddElements.forEach((oddElement) => {
      const oddForNumber = RouletteHelper.statForNumber(oddElement.number, rouletteStats);
      oddElement.fill(roundInfo, oddForNumber);
    });
  }

  public init(numberTexture: PIXI.Texture): void {
    this.oddElements.forEach((oddElement) => oddElement.init(numberTexture.clone()));
  }

  public createAnims(): IAnimInterval[] {
    const result = [{ startTime: 15, duration: 63 }];
    return result;
  }

  public update(dt: number): void {
    super.update(dt);
    const currentTime = Logic.getVideoTime();
    const anim = Logic.getAnim(currentTime, this.anims, this);

    if (!anim) {
      this.visible = false;
      return;
    }
    this.visible = true;
    const baseFactor = currentTime - anim.startTime;
    AnimHelper.animateInOut(baseFactor, 0, anim.duration, 2, 0, 1, (val) => (this.alpha = val), 0.5, 0);
  }

  private createGridLayout(numbers: StatOddNumber[]) {
    let row = 0;
    let column = 0;
    for (const number of numbers) {
      number.position.x = _s(230) * column;
      number.position.y = _s(48.2) * row;
      row += 1;
      if (number.number === 9 || number.number === 18 || number.number === 27) {
        column += 1;
        row = 1;
      }
    }
  }
}

import * as PIXI from "pixi.js";
import { Group } from "../../../Graphics/Group";
import { Logic, _s, _t, settings } from "../../../Logic/Logic";
import { AnimHelper } from "./../../common/Anim";
import { IRoundHistory, IDriver, IAnimInterval, IDog63RoundHistory, DriverPattern } from "../../../Logic/LogicDefinitions";
//import { BonusAnnotationDog } from "./BonusAnnotationDog";
import { MultiStyleText } from "./../../common/MultiStyleText";
import { DrawHelper } from "../../common/DrawHelper";
import { GameType, GameLength } from "../../../common/Definitions";
import { Dog63Helper } from "../Dog63Helper";
import { LayoutHelper } from "../../../VideoScreen/Util/LayoutHelper";

export class Dog633rdColumn extends Group {
  // private titleText: PIXI.Text;
  // private rows: RowItem[] = [];
  private anims: (IAnimInterval & { fadeInFactor?: number; fadeOutFactor?: number })[] = [];
  private gameType: GameType;
  private gameLength: GameLength;

  private headers: PIXI.Text[] = [];
  private driverNumber: PIXI.Text;
  private driverName: PIXI.Text;
  private secondPlacements: PIXI.Text[] = [];
  private thirdPlacements: PIXI.Text[] = [];
  private quotes: PIXI.Text[] = [];

  private bars: PIXI.Sprite[][] = [];

  private quoteStyleRegular: PIXI.TextStyle;
  private quoteStyleBold: PIXI.TextStyle;

  public constructor(gameType: GameType, gameLength: GameLength) {
    super();

    this.gameType = gameType;
    this.gameLength = gameLength;

    this.showDebug(settings.debug);

    const headerStyle = new PIXI.TextStyle({
      fontFamily: "DIN-Bold",
      fontSize: _s(14),
      fill: "black",
      align: "center"
    });

    const placementStyle = new PIXI.TextStyle({
      fontFamily: "DIN-Bold",
      fontSize: _s(20),
      fill: Dog63Helper.getWhiteColor(),
      align: "center"
    });
    this.quoteStyleRegular = new PIXI.TextStyle({
      fontFamily: "DIN-Regular",
      fontSize: _s(20),
      fill: Dog63Helper.getWhiteColor(),
      align: "center",
      letterSpacing: _s(-0.5)
    });
    this.quoteStyleBold = new PIXI.TextStyle({
      fontFamily: "DIN-Bold",
      fontSize: _s(20),
      fill: Dog63Helper.getWhiteColor(),
      align: "center"
    });

    for (let i = 0; i < 3; i++) {
      const header1 = Logic.createPixiText(headerStyle);
      header1.anchor.set(0.5, 0);
      this.headers.push(header1);
      this.add(header1);
    }
    {
      const driverNameStyle = new PIXI.TextStyle({
        fontFamily: "DIN-LightItalic",
        fontSize: _s(18),
        fill: Dog63Helper.getWhiteColor(),
        align: "center",
        letterSpacing: _s(0.5)
      });
      this.driverName = Logic.createPixiText(driverNameStyle);
      this.driverName.anchor.set(1, 0);
      this.driverName.rotation = (-Math.PI * 90) / 180.0;
      this.add(this.driverName);
    }
    {
      const driverNumberStyle = new PIXI.TextStyle({
        fontFamily: "DIN-Regular",
        fontSize: _s(22),
        fill: Dog63Helper.getWhiteColor(),
        align: "center"
      });
      this.driverNumber = Logic.createPixiText(driverNumberStyle);
      this.driverNumber.anchor.set(0.5, 0);
      //this.add(this.driverNumber);
    }
    for (let i = 0; i < 5; i++) {
      const placement = Logic.createPixiText(placementStyle);
      this.secondPlacements.push(placement);
      placement.anchor.set(0.5, 0);
      //this.add(placement);
    }
    for (let i = 0; i < 25; i++) {
      const placement = Logic.createPixiText(placementStyle);
      placement.anchor.set(0.5, 0);
      this.thirdPlacements.push(placement);
      //this.add(placement);
    }
    for (let i = 0; i < 25; i++) {
      const quote = Logic.createPixiText(this.quoteStyleRegular);
      quote.anchor.set(0.5, 0);
      this.quotes.push(quote);
      this.add(quote);
    }
    for (let column = 0; column < 3; column++) {
      const barColumn: PIXI.Sprite[] = [];
      for (let row = 0; row < 25; row++) {
        const bar = new PIXI.Sprite();
        barColumn.push(bar);
        //this.add(bar);
      }
      this.bars.push(barColumn);
    }
  }

  public createAnims(gameType: GameType, gameLength: GameLength, withBonus: boolean): IAnimInterval[] {
    // if (gameType === "dog63"){
    //   switch (gameLength){
    //default:
    {
      return [{ startTime: 30.3, duration: 15.3 }, { startTime: 96.1, duration: 14.6 }, { startTime: 148.9, duration: withBonus ? 10.5 : 15.0 } as IAnimInterval];
    }

    //  }
    //}
  }

  public fill(drivers: IDriver[], driverIndex: number, quotes: number[], withBonus: boolean, lowestQuotes: number[], highestQuotes: number[]): void {
    /*
    private headers: PIXI.Text[] = [];
  private driverNumber: PIXI.Text;
  private driverName: PIXI.Text;
  private secondPlacements: PIXI.Text[] = [];
  private thirdPlacements: PIXI.Text[] = [];*/

    const columnDriver = drivers[driverIndex];

    this.headers[0].text = _t("numberSign");
    this.headers[1].text = _t("numberSignTwo");
    this.headers[2].text = _t("numberSignThree");
    this.driverNumber.text = (driverIndex + 1).toString();
    this.driverName.text = columnDriver.firstName;

    const values = [1, 2, 3, 4, 5, 6];
    values.splice(values.indexOf(driverIndex + 1), 1);
    const otherDrivers = values;
    for (let i = 0; i < 5; i++) {
      this.secondPlacements[i].text = "" + otherDrivers[i];
    }
    for (let i = 0; i < 5; i++) {
      const placements = [...otherDrivers];
      placements.splice(otherDrivers.indexOf(otherDrivers[i]), 1);
      for (let j = 0; j < 4; j++) {
        this.thirdPlacements[i * 4 + j].text = "" + placements[j];
        this.quotes[i * 4 + j].text = Dog63Helper.formatQuote(quotes[i * 4 + j], 6);
        this.quotes[i * 4 + j].tint = Dog63Helper.getWhiteColorNumber();
        const quoteValue = quotes[i * 4 + j];
        if (lowestQuotes.indexOf(quoteValue) >= 0) {
          this.quotes[i * 4 + j].tint = Dog63Helper.getGreenColorNumber();
          this.quotes[i * 4 + j].style = lowestQuotes[0] === quoteValue ? this.quoteStyleBold : this.quoteStyleRegular;
        } else if (highestQuotes.indexOf(quoteValue) >= 0) {
          this.quotes[i * 4 + j].style = highestQuotes[highestQuotes.length - 1] === quoteValue ? this.quoteStyleBold : this.quoteStyleRegular;
          this.quotes[i * 4 + j].tint = Dog63Helper.getRedColorNumber();
        }

        this.bars[0][i * 4 + j].texture = DrawHelper.getCachedPattern(
          6,
          32,
          0,
          columnDriver.color,
          columnDriver.color2,
          columnDriver.driverPattern === DriverPattern.BLACK_WHITE_6 ? DriverPattern.BLACK_WHITE_6_b : columnDriver.driverPattern
        );
        const secondDriver = drivers[otherDrivers[i] - 1];
        const thirdDriver = drivers[placements[j] - 1];

        this.bars[1][i * 4 + j].texture = DrawHelper.getCachedPattern(
          6,
          32,
          0,
          secondDriver.color,
          secondDriver.color2,
          secondDriver.driverPattern === DriverPattern.BLACK_WHITE_6 ? DriverPattern.BLACK_WHITE_6_b : secondDriver.driverPattern
        );
        this.bars[2][i * 4 + j].texture = DrawHelper.getCachedPattern(
          6,
          32,
          0,
          thirdDriver.color,
          thirdDriver.color2,
          thirdDriver.driverPattern === DriverPattern.BLACK_WHITE_6 ? DriverPattern.BLACK_WHITE_6_b : thirdDriver.driverPattern
        );
      }
    }

    this.anims = this.createAnims(this.gameType, this.gameLength, withBonus);
  }

  public onLayout(): void {
    const rowHeight = 30.7;
    const rowBlockHeight = rowHeight * 4; // 122;

    const rowOffsetY = 24;
    const rowOffsetX = 15;

    const columnWidth = 38;
    this.driverNumber.x = _s(rowOffsetX);
    this.driverNumber.y = _s(rowOffsetY);

    this.driverName.y = _s(56);
    this.driverName.x = _s(7);

    for (let i = 0; i < 3; i++) {
      this.headers[i].x = _s(rowOffsetX + (columnWidth - 1) * i - 2 + 6);
      this.headers[i].y = _s(4);

      for (let row = 0; row < 20; row++) {
        LayoutHelper.setScaledRectangleSprite(this.bars[i][row], columnWidth * i + columnWidth - 6, rowHeight * row - 3 + rowOffsetY, 5, rowHeight);
      }
    }

    for (let i = 0; i < this.secondPlacements.length; i++) {
      this.secondPlacements[i].x = _s(rowOffsetX + columnWidth);
      this.secondPlacements[i].y = _s(rowOffsetY + rowBlockHeight * i);
      for (let r = 0; r < 4; r++) {
        this.thirdPlacements[i * 4 + r].x = _s(rowOffsetX + columnWidth * 2);

        const thirdPlacementY = rowOffsetY + rowBlockHeight * i + rowHeight * r;
        this.thirdPlacements[i * 4 + r].y = _s(thirdPlacementY);
        this.quotes[i * 4 + r].x = _s(156);
        this.quotes[i * 4 + r].y = _s(thirdPlacementY);
      }
    }
  }

  public updateAnms(dt: number, duration: number): void {
    // fade in/out
  }
}

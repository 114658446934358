import { Group } from "../../../Graphics/Group";
import { Logic, _s, _t, settings } from "../../../Logic/Logic";
import { IAnimInterval, IGameInfo, IRoundHistory, VideoState } from "../../../Logic/LogicDefinitions";
import { AnimHelper } from "../../../VideoScreen/common/Anim";
import * as PIXI from "pixi.js";
import bonusAnnotationRed from "../../../assets/c4/dog6/BonusAnnotation.png";
import numbersLeftDog6 from "../../../assets/c4/dog6/NumbersLeft.png";
import arrowDownRed from "../../../assets/c4/dog6/RedArrowDown.png";
import historyBarBackground from "../../../assets/c4/historyBarBackground.png";
import historyBarItemBackground from "../../../assets/c4/historyElement.png";
import arrowDownBlue from "../../../assets/c4/horse/BlueArrowDown.png";
import bonusAnnotationBlue from "../../../assets/c4/horse/BonusAnnotation.png";
import numbersLeftHorse from "../../../assets/c4/horse/NumbersLeft.png";
import rouletteBallTexture from "../../../assets/c4/roulette/NumbersLeft.png";
import rouletteArrowTexture from "../../../assets/c4/roulette/VideoArrow.png";
import { GameLength, GameType, SkinType, SkinTypeDefinition } from "../../../common/Definitions";
import { IRouletteRoundHistory } from "./../../../Logic/LogicDefinitions";
import { HistoryBarItem } from "./HistoryBarItem";

export class HistoryBar extends Group {
  private gameType: GameType;
  private skinType: SkinType;
  private withBonus: boolean;
  private withAnimation?: boolean = false;
  private historyBarSprite: PIXI.Sprite = new PIXI.Sprite();
  private historyBarTexture: PIXI.Texture | undefined;
  private titleText: PIXI.Text;
  private arrowDownSprite: PIXI.Sprite = new PIXI.Sprite();
  private arrowDownSpriteTexture: PIXI.Texture | undefined;
  private historyBarMask = new PIXI.Graphics();
  private historyBarItems: HistoryBarItem[] = [];
  public anims: IAnimInterval[] = [];

  public constructor(gameInfo: IGameInfo, withAnimation?: boolean) {
    super();
    this.withBonus = gameInfo.haveDbPot;
    this.withAnimation = withAnimation;
    this.gameType = gameInfo.gameType;
    this.skinType = gameInfo.gameSkin;
    this.anims = this.createAnims(gameInfo.gameType, gameInfo.gameSkin);
    this.width = 245.25;
    this.height = 540;

    // Init historybar components
    const titleTextStyle = new PIXI.TextStyle({
      fontFamily: "Roboto-Bold",
      fontSize: _s(22),
      fill: 0x111111
    });
    this.titleText = Logic.createPixiText(titleTextStyle);

    // Add historybar components
    this.add(this.historyBarSprite);
    this.add(this.arrowDownSprite);
    this.add(this.historyBarMask);
    this.add(this.titleText);

    // Add history bar items
    for (let index = 0; index < 8; index++) {
      const historyBarItem = new HistoryBarItem(this.gameType);
      this.add(historyBarItem);
      this.historyBarItems.push(historyBarItem);
    }

    if (settings.debug) {
      this.historyBarSprite.alpha = 0.5;
    }
  }

  public createAnims(gameType: GameType, skinType: SkinType): IAnimInterval[] {
    if (gameType === "roulette" && skinType === SkinTypeDefinition.CLASSIC) {
      return [{ startTime: 0, duration: 203.5 }];
    } else if (gameType === "horse" && skinType === SkinTypeDefinition.CLASSIC) {
      return [{ startTime: 0, duration: Logic.getIntroLength() - 5 }];
    } else {
      return [{ startTime: 0, duration: Logic.getIntroLength() - 5 }];
    }
  }

  public onLayout(): void {
    let positionY = this.withBonus ? 105 : 157;

    if (this.gameType === "roulette") {
      positionY = 156.5;
      this.height = 536;
    }

    this.position.x = _s(45);
    this.position.y = _s(positionY);

    // Add sprite shapes
    this.historyBarSprite.width = _s(this.width);
    this.historyBarSprite.height = _s(this.height);
    // this.historyBarSprite.visible = false

    // Layout title
    this.titleText.anchor.x = 0.5;
    this.titleText.position.y = _s(11);
    this.titleText.position.x = _s(this.width / 2);

    // mask for animation history bar background & historyBarItems
    this.historyBarMask.cacheAsBitmap = false;
    this.historyBarMask.beginFill(0xffffff);
    this.historyBarMask.drawRect(_s(0), _s(0), _s(this.width), _s(this.height));
    this.historyBarMask.endFill();
    this.historyBarMask.pivot.set(0.5, 0.5);
    this.historyBarMask.cacheAsBitmap = true;

    // Set masks
    this.historyBarSprite.mask = this.historyBarMask;
    this.arrowDownSprite.mask = this.historyBarMask;
    this.titleText.mask = this.historyBarMask;
    this.historyBarItems.forEach((item) => {
      item.setMask(this.historyBarMask);
    });

    // Layout arrow down sprite
    this.arrowDownSprite.anchor.x = 0.5;
    this.arrowDownSprite.position.y = _s(33);
    this.arrowDownSprite.position.x = _s(this.width / 2 + 3);
    this.arrowDownSprite.width = _s(90);
    this.arrowDownSprite.height = _s(40);

    if (this.gameType === "roulette") {
      this.arrowDownSprite.height = _s(30);
      this.arrowDownSprite.position.y = _s(36);
    }
  }

  public async init(): Promise<void> {
    let numbersSpriteTexture: PIXI.Texture;
    let bonusAnnotationTexture: PIXI.Texture;

    // Init history bar texture
    this.historyBarTexture = await Logic.loadTexture(historyBarBackground);
    const historyBarItemTexture = await Logic.loadTexture(historyBarItemBackground);

    // Init bonus annotation texture
    if (this.gameType === "horse" && this.skinType === SkinTypeDefinition.CLASSIC) {
      bonusAnnotationTexture = await Logic.loadTexture(bonusAnnotationBlue);
    } else {
      bonusAnnotationTexture = await Logic.loadTexture(bonusAnnotationRed);
    }

    // set numbersprite texture here and pass copy to elemements
    // if loaded in element, texture has to be loaded 8 times, not performant
    if (this.gameType === "roulette" && this.skinType === SkinTypeDefinition.CLASSIC) {
      numbersSpriteTexture = await Logic.loadTexture(rouletteBallTexture);
    } else if (this.gameType === "horse" && this.skinType === SkinTypeDefinition.CLASSIC) {
      numbersSpriteTexture = await Logic.loadTexture(numbersLeftHorse);
    } else {
      numbersSpriteTexture = await Logic.loadTexture(numbersLeftDog6);
    }

    let frameWidth;
    let frameHeight;

    if (this.gameType === "roulette" && this.skinType === SkinTypeDefinition.CLASSIC) {
      this.arrowDownSpriteTexture = await Logic.loadTexture(rouletteArrowTexture);
      frameWidth = 146;
      frameHeight = 50;
    } else if (this.gameType === "horse" && this.skinType === SkinTypeDefinition.CLASSIC) {
      this.arrowDownSpriteTexture = await Logic.loadTexture(arrowDownBlue);
      frameWidth = 120;
      frameHeight = 50;
    } else {
      this.arrowDownSpriteTexture = await Logic.loadTexture(arrowDownRed);
      frameWidth = 120;
      frameHeight = 50;
    }

    const arrowDownSpriteTexture = this.arrowDownSpriteTexture.clone();
    arrowDownSpriteTexture.frame = new PIXI.Rectangle(0, 0, frameWidth, frameHeight);

    // Init history bar arrow dow texture
    this.historyBarSprite.texture = this.historyBarTexture;
    this.arrowDownSprite.texture = arrowDownSpriteTexture;

    let startY = _s(70);
    let historyBarItemOffset = _s(57.2);

    if (this.gameType === "roulette") {
      startY = _s(65.5);
      historyBarItemOffset = _s(57.5);
    }

    // Init history bar items
    this.historyBarItems.forEach((historyBarItem, index) => {
      historyBarItem.init(historyBarItemTexture.clone(), bonusAnnotationTexture.clone(), numbersSpriteTexture.clone());
      historyBarItem.position.y = startY + index * historyBarItemOffset;
    });
  }

  public fill(history: IRoundHistory[] | IRouletteRoundHistory[], titleText?: string): void {
    // Fill title text
    this.titleText.text = _t(titleText ?? "ISTORIJA");

    // Fill history bar items
    this.historyBarItems.forEach((historyBarItem, index) => {
      if (this.gameType === "roulette") {
        historyBarItem.fillRoulette((history[index] as IRouletteRoundHistory) ?? "");
      } else {
        historyBarItem.fill((history[index] as IRoundHistory) ?? "");
      }
    });
  }

  public update(dt: number): void {
    super.update(dt);
    const currentTime = Logic.getVideoTime();
    const anim = Logic.getAnim(currentTime, this.anims, this);

    if (!anim) {
      this.visible = false;
      return;
    }
    this.visible = true;
    const baseFactor = currentTime - anim.startTime;
    AnimHelper.animateInOut(baseFactor, 0, anim.duration, 0, 0, 1, (val) => (this.alpha = val), 0.00001, 0);

    if (this.withAnimation) {
      const delta = 0.07;

      // Animate history bar sprite
      AnimHelper.animateInOut(
        baseFactor,
        0.07,
        anim.duration,
        0.5,
        0,
        1,
        (val) => {
          this.historyBarSprite.height = _s(this.height) * val;
          this.historyBarSprite.width = _s(this.width);
        },
        0,
        0
      );

      // Animate title text and arrow down sprite
      AnimHelper.animateInOut(
        baseFactor,
        0.2,
        anim.duration,
        0.5,
        0,
        1,
        (val) => {
          this.titleText.alpha = val;
          this.arrowDownSprite.alpha = val;
        },
        0,
        0
      );

      this.historyBarItems.forEach((item, index) => {
        AnimHelper.animateIn(baseFactor, delta * index, 0, 0.3, 0, 1, (val) => (item.x = _s(0) - _s(400) * (1 - val)));
      });
    }
  }
}

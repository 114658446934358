import * as React from "react";
import { RtcLogic } from "../Rtc/RtcLogic";
import { makeStyles } from "@material-ui/core/styles";
import { asComponent } from "./ReactHelper";
import { Util } from "../common/Util";

const useStyles = makeStyles({
  "@global": {
    html: {
      width: "100%",
      height: "100%",
      margin: 0,
      padding: 0
    },
    body: {
      width: "100%",
      height: "100%",
      margin: 0,
      padding: 0,
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
    }
  },
  video: {
    // width: "400px",
    // height: "300px",
    margin: 0,
    objectFit: "cover"
  }
});

interface IProps { }

export const ReactConsumer = asComponent("ReactConsumer", (props: IProps) => {
  const savedMediaStream = React.useRef<MediaStream>();
  const styles = useStyles();

  function setSavedMediaStream() {
    const video = document.querySelector("video");
    if (video && savedMediaStream.current) {
      if (video.srcObject !== savedMediaStream.current) video.srcObject = savedMediaStream.current;
      try {
        video.onplay = function () {
          console.log("Play Video:" + Date.now());
        };
        video.muted = false;
        video.play();
      } catch (e) {
        console.log("Muted false not supported!");
        video.muted = true;
        video.play();
      }
    }
  }

  React.useEffect(() => {
    RtcLogic.instance.rtcClient!.consumeStreams((streams: MediaStream[]) => {
      savedMediaStream.current = streams[0];
      setSavedMediaStream();
    });
  }, []);

  const attachVideo = (element: HTMLVideoElement | null) => {
    if (element) {
      document.addEventListener("click", () => {
        setSavedMediaStream();
      });
    }
  };

  console.log("Start add Video:" + Date.now());

  let width = "100%";
  const settingsWidth = Util.getUrlParameterIntOrUndefined(window.location.href, "width");
  if (settingsWidth) width = settingsWidth + "px";

  // !!! muted !!! => autoPlay won't work otherwise!!!
  return (
    <>
      <video style={{ width }} className={styles.video} playsInline ref={(el) => attachVideo(el)}></video>
    </>
  );
});

import * as PIXI from "pixi.js";
import { Group } from "../../../Graphics/Group";
import { Logic, _s, _t, settings } from "../../../Logic/Logic";
import { Dog63Helper } from "../Dog63Helper";
import { IDog63QuoteInfo } from "../../../Logic/LogicDefinitions";

export class Dog63QuotesMiddle extends Group {
  private title: PIXI.Text;
  private headersVertical: PIXI.Text[] = [];
  private places: PIXI.Text[][] = [];
  private quotes: PIXI.Text[][] = [];

  public constructor() {
    super();

    this.showDebug(settings.debug, undefined, "Top");

    {
      const style = new PIXI.TextStyle({
        fontFamily: "DIN-Light",
        fontSize: _s(22),
        fill: Dog63Helper.getWhiteColor(),
        letterSpacing: _s(0),
        align: "left"
      });
      this.title = Logic.createPixiText(style);
      this.add(this.title);

      const headersStyle = new PIXI.TextStyle({
        fontFamily: "DIN-Light",
        fontSize: _s(22),
        fill: Dog63Helper.getWhiteColor(),
        letterSpacing: _s(0.5),
        align: "left"
      });
      for (let i = 0; i < 2; i++) {
        const header = Logic.createPixiText(headersStyle);
        this.headersVertical.push(header);
        this.add(header);
      }
    }

    const placeStyle = new PIXI.TextStyle({
      fontFamily: "DIN-Light",
      fontSize: _s(19),
      fill: Dog63Helper.getWhiteColor(),
      letterSpacing: _s(-1),
      align: "left"
    });
    const quoteStyle = new PIXI.TextStyle({
      fontFamily: "DIN-Medium",
      fontSize: _s(19),
      fill: Dog63Helper.getWhiteColor(),
      letterSpacing: _s(-1),
      align: "right"
    });
    for (let line = 0; line < 2; line++) {
      const placeLine: PIXI.Text[] = [];
      const quoteLine: PIXI.Text[] = [];
      for (let column = 0; column < 10; column++) {
        const place = Logic.createPixiText(placeStyle);
        place.anchor.set(0, 0);
        placeLine.push(place);
        this.add(place);

        const quote = Logic.createPixiText(quoteStyle);
        quote.anchor.set(1, 1.0);
        quoteLine.push(quote);
        this.add(quote);
      }
      this.places.push(placeLine);
      this.quotes.push(quoteLine);
    }
  }

  public fill(quotes: IDog63QuoteInfo[][]): void {
    this.title.text = _t("sumPlaces");
    this.headersVertical[0].text = _t("sumPlaceSh2");
    this.headersVertical[1].text = _t("sumPlaceSh3");

    const suPlaces = [
      [3, 4, 5, 6, 7, 8, 9, 10, 11],
      [6, 7, 8, 9, 10, 11, 12, 13, 14, 15]
    ];

    for (let line = 0; line < 2; line++) {
      let minValueIndex = -1;
      let maxValueIndex = -1;

      for (let column = 0; column < 10; column++) {
        if (line === 0 && column === 9) continue;

        this.places[line][column].text = "" + suPlaces[line][column] + ":";
        //this.quotes[line][column].text = Dog63Helper.formatQuote(quotes[line][column]);
        this.quotes[line][column].text = Dog63Helper.formatQuote(quotes[line][column].quote, quotes[line][column].betCodeId);
        this.quotes[line][column].tint = Dog63Helper.getWhiteColorNumber();
        Logic.autoSize(this.quotes[line][column], _s(45));
        if (minValueIndex < 0 || quotes[line][minValueIndex] > quotes[line][column]) minValueIndex = column;
        if (maxValueIndex < 0 || quotes[line][maxValueIndex] < quotes[line][column]) maxValueIndex = column;
      }

      this.quotes[line][minValueIndex].tint = Dog63Helper.getGreenColorNumber();
      this.quotes[line][maxValueIndex].tint = Dog63Helper.getRedColorNumber();
    }
  }

  public onLayout(): void {
    this.title.x = _s(37);
    this.title.y = _s(6);

    const rowHeight = 47.7;
    const headerOffsetX = 23;
    const rowOffsetY = 3.4;
    const columnSpacingX = 89;
    const placeOffsetX = 141;
    const quoteOffsetX = 142;

    const columnPositionsPlace: number[] = [121, 210, 299, 386, 474, 562, 650, 738, 826, 912];
    const columnPositionQuote: number[] = [192, 281, 369, 456, 544, 632, 720, 808, 895, 983];

    for (let line = 0; line < 2; line++) {
      this.headersVertical[line].x = _s(headerOffsetX);
      this.headersVertical[line].y = _s(rowOffsetY + rowHeight * line + rowHeight);

      for (let column = 0; column < 10; column++) {
        this.places[line][column].x = _s(columnPositionsPlace[column]); // _s(placeOffsetX + columnSpacingX*column);
        this.quotes[line][column].x = _s(columnPositionQuote[column]);
        this.places[line][column].y = _s(rowOffsetY + rowHeight * line + rowHeight);
        this.quotes[line][column].y = _s(rowOffsetY + rowHeight * line + rowHeight + 21);
      }
    }
  }

  public updateAnim(baseFactor: number, duration: number): void { }
}

import * as PIXI from "pixi.js";
import { DriverPattern } from "../../Logic/LogicDefinitions";
import { Util } from "../../common/Util";

interface ISolidFill {
  type: "solid";
  color: string;
}

interface IGradientFill {
  type: "gradient";
  color: string;
  color2: string;
  verti?: boolean;
  segments?: number;
}

export type Fill = ISolidFill | IGradientFill;

export class DrawHelper {
  public static cachedPatterns: Record<string, PIXI.Texture> = {};
  public static cachedTextures: Record<string, PIXI.Texture> = {};
  public static cachedRenderTextures: Record<string, PIXI.RenderTexture> = {};

  public static dispose() {
    for (const c in this.cachedPatterns) {
      if (c && this.cachedPatterns[c] !== undefined) {
        this.cachedPatterns[c].destroy();
      }
    }
    DrawHelper.cachedPatterns = {};

    for (const c in this.cachedTextures) {
      if (c && this.cachedTextures[c] !== undefined) {
        this.cachedTextures[c].destroy();
      }
    }
    DrawHelper.cachedTextures = {};

    for (const c in this.cachedRenderTextures) {
      if (c && this.cachedRenderTextures[c] !== undefined) {
        this.cachedRenderTextures[c].destroy();
      }
    }
    DrawHelper.cachedRenderTextures = {};
  }

  public static getCachedRenderTexture(id: number, width: number, height: number): PIXI.RenderTexture {
    const key = [id, width, height].join("_");

    let texture = this.cachedRenderTextures[key];
    if (texture === undefined) {
      texture = PIXI.RenderTexture.create({ width, height });
      this.cachedRenderTextures[key] = texture;
    }
    return texture;
  }

  public static getCachedTriangleTexture(width: number, height: number, color: string, flipped: boolean, triangleWidth: number): PIXI.Texture {
    const key = ["triangle", width, height, color, flipped, triangleWidth].join("_");

    let texture = this.cachedTextures[key];
    if (texture === undefined) {
      texture = DrawHelper.createTriangleTexture(width, height, color, flipped, triangleWidth);
      this.cachedTextures[key] = texture;
    }
    return texture;
  }

  public static getCachedHausTexture(width: number, height: number, color: string, flipped: boolean): PIXI.Texture {
    const key = ["haus", width, height, color, flipped].join("_");

    let texture = this.cachedTextures[key];
    if (texture === undefined) {
      texture = DrawHelper.createHausTexture(width, height, color, flipped);
      this.cachedTextures[key] = texture;
    }
    return texture;
  }

  public static createTriangleTexture(width: number, height: number, color: string, flipped: boolean, triangleWidth: number): PIXI.Texture {
    const canvas = document.createElement("canvas");
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext("2d")!;

    ctx.fillStyle = "transparent";
    ctx.fill();
    ctx.fillRect(0, 0, width, height);

    ctx.beginPath();
    ctx.fillStyle = color;

    const xOffset = triangleWidth;
    const yOffset = (flipped ? -triangleWidth : triangleWidth) * 2;

    const top = flipped ? height : 0;
    const bottom = flipped ? 0 : height;
    ctx.moveTo(0, bottom);
    ctx.lineTo(xOffset, bottom);
    ctx.lineTo(width / 2, top + yOffset);
    ctx.lineTo(width - xOffset, bottom);
    ctx.lineTo(width, bottom);
    ctx.lineTo(width / 2, top);
    ctx.lineTo(0, bottom);
    ctx.closePath();
    ctx.fill();

    const baseTexture = new PIXI.BaseTexture(canvas); // don't cache
    const maskTexture = new PIXI.Texture(baseTexture);
    return maskTexture;
  }

  public static createHausTexture(width: number, height: number, color: string, flipped: boolean): PIXI.Texture {
    const canvas = document.createElement("canvas");
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext("2d")!;

    ctx.beginPath();
    ctx.fillStyle = color;

    const top = flipped ? height : 0;
    const bottom = flipped ? 0 : height;

    ctx.moveTo(0, bottom);
    ctx.lineTo(width, bottom);
    ctx.lineTo(width, height / 2);
    ctx.lineTo(width / 2, top);
    ctx.lineTo(0, height / 2);
    ctx.lineTo(0, bottom);
    ctx.closePath();
    ctx.fill();

    // const texture = PIXI.Texture.from(canvas);
    const baseTexture = new PIXI.BaseTexture(canvas); // don't cache
    const maskTexture = new PIXI.Texture(baseTexture);
    return maskTexture;
  }

  public static getCachedPattern(width: number, height: number, skewed: number, color: number, color2?: number, pattern?: number) {
    const key = [width, height, skewed, color, color2, pattern].join("_");

    let texture = this.cachedPatterns[key];
    if (texture === undefined) {
      texture = DrawHelper.drawPatternTexture(width, height, skewed, color, color2, pattern);
      this.cachedPatterns[key] = texture;
    }
    return texture;
  }

  public static drawPatternTexture(width: number, height: number, skewed: number, color: number, color2?: number, pattern?: DriverPattern) {
    if (pattern === undefined) pattern = DriverPattern.COLOR_ONLY;
    if (color2 === undefined) color2 = 0xff000000;

    switch (pattern) {
      case DriverPattern.COLOR_ONLY:
        return DrawHelper.createSkewedRoundedRectangleTexture(width, height, 0, skewed, { type: "solid", color: Util.rgbToHex(color) });
      case DriverPattern.YELLOW_BLACK_2:
        return DrawHelper.createSkewedRoundedRectangleTexture(width, height, 0, skewed, { type: "gradient", color: Util.rgbToHex(color), color2: Util.rgbToHex(color2), segments: 2, verti: true });
      case DriverPattern.BLACK_WHITE_6:
        return DrawHelper.createSkewedRoundedRectangleTexture(width, height, 0, skewed, { type: "gradient", color: Util.rgbToHex(color), color2: Util.rgbToHex(color2), segments: 5 });
      case DriverPattern.BLACK_WHITE_6_b:
        return DrawHelper.createSkewedRoundedRectangleTexture(width, height, 0, skewed, { type: "gradient", color: Util.rgbToHex(color), color2: Util.rgbToHex(color2), segments: 8 });
    }
  }

  public static createSkewedRoundedRectangleTexture(width: number, height: number, radius: number, skewPixels: number, fillStyle: Fill) {
    const canvas = document.createElement("canvas");
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext("2d")!;
    let fill: string | CanvasGradient | CanvasPattern;
    if (fillStyle.type === "gradient" && fillStyle.segments) {
      // pattern
      // if (fillStyle.verti) {
      if (fillStyle.color2) {
        DrawHelper.drawPatternTextureToContext(
          ctx,
          0,
          0,
          width,
          height,
          fillStyle.color,
          fillStyle.type === "gradient" ? fillStyle.color2 : "",
          fillStyle.type === "gradient" ? fillStyle.segments : 1,
          skewPixels
        );
      }
      // } else {

      // }
    } else {
      if (fillStyle.type === "gradient") {
        // gradient
        if (fillStyle.verti) fill = DrawHelper.createGradient(ctx, 0, 0, 0, ctx.canvas.height, fillStyle.color, fillStyle.color2);
        else fill = DrawHelper.createGradient(ctx, skewPixels * 0.5, ctx.canvas.height, ctx.canvas.width, ctx.canvas.height, fillStyle.color, fillStyle.color2);
      } else {
        // solid fill
        fill = fillStyle.color;
      }
      DrawHelper.drawSkewedRoundedRectangle(ctx, 0, 0, width, height, radius, skewPixels, fill);
    }
    // const texture = PIXI.Texture.from(canvas);
    const baseTexture = new PIXI.BaseTexture(canvas); // don't cache
    const texture = new PIXI.Texture(baseTexture);
    return texture;
  }

  public static drawSkewedRoundedRectangle(
    ctx: CanvasRenderingContext2D,
    x: number,
    y: number,
    width: number,
    height: number,
    radius: number,
    skewPixels: number,
    fillStyle: string | CanvasGradient | CanvasPattern
  ) {
    const skewFactor = skewPixels / height;
    ctx.beginPath();

    ctx.fillStyle = fillStyle;

    // const oldTransform = ctx.getTransform(); // not supported on n2
    ctx.transform(1, 0, -skewFactor, 1, x, y);
    ctx.moveTo(skewPixels, radius);
    ctx.lineTo(skewPixels + radius, 0);
    ctx.lineTo(width, 0);
    ctx.lineTo(width, height - radius);
    ctx.lineTo(width - radius, height);
    ctx.lineTo(skewPixels, height);
    ctx.closePath();
    ctx.arc(skewPixels + radius, radius, radius, 0, Math.PI * 2);
    ctx.arc(width - radius, height - radius, radius, 0, Math.PI * 2);
    ctx.fill();
    // ctx.setTransform(oldTransform);
  }

  public static createSkewedRoundedRectangleGraphics(x: number, y: number, width: number, height: number, radius: number, skewPixels: number) {
    const skewFactor = skewPixels / height;

    const gfx = new PIXI.Graphics();
    const m = new PIXI.Matrix(1, 0, -skewFactor, 1, x, y);
    gfx.transform.setFromMatrix(m);
    gfx.beginFill(0x555555);
    gfx.drawPolygon([
      new PIXI.Point(skewPixels, radius),
      new PIXI.Point(skewPixels + radius, 0),
      new PIXI.Point(width, 0),
      new PIXI.Point(width, height - radius),
      new PIXI.Point(width - radius, height),
      new PIXI.Point(skewPixels, height)
    ]);

    gfx.drawCircle(skewPixels + radius, radius, radius);
    gfx.drawCircle(width - radius, height - radius, radius);
    gfx.endFill();
    return gfx;
  }

  public static createGradient(ctx: CanvasRenderingContext2D, x0: number, y0: number, x1: number, y1: number, color1: string, color2: string) {
    const gradient = ctx.createLinearGradient(x0, y0, x1, y1);
    gradient.addColorStop(0, color1);
    gradient.addColorStop(1, color2);
    return gradient;
  }

  public static drawPatternTextureToContext(
    ctx: CanvasRenderingContext2D,
    x: number,
    y: number,
    width: number,
    height: number,
    color1: string,
    color2: string,
    segments: number,
    skewPixels: number,
    vertical: boolean = false
  ) {
    const skewFactor = -skewPixels / height;
    // const oldTransform = ctx.getTransform(); // not supported on n2
    const segmentHeight = vertical ? (width - skewPixels) / segments : height / segments;

    ctx.transform(1, 0, skewFactor, 1, x, y);
    for (let s = 0; s < segments; s++) {
      const currentColor = s % 2 === 0 ? color1 : color2;
      ctx.fillStyle = currentColor;
      if (vertical) ctx.fillRect(x + skewPixels + segmentHeight * s, y, segmentHeight, height);
      else ctx.fillRect(x + skewPixels, y + segmentHeight * s, width - skewPixels, segmentHeight);
    }
    // ctx.setTransform(oldTransform);
  }

  public static createNGonGraphics(x: number, y: number, radius: number, corners: number) {
    const gfx = new PIXI.Graphics();
    gfx.beginFill(0x555555);

    const vertices: PIXI.Point[] = [];
    for (let i = 0; i < corners; i++) {
      const vx = x + radius * Math.cos((2 * Math.PI * i) / corners);
      const vy = y + radius * Math.sin((2 * Math.PI * i) / corners);
      vertices.push(new PIXI.Point(vx, vy));
    }

    gfx.drawPolygon(vertices);
    gfx.endFill();
    return gfx;
  }

  public static drawNGonGraphics(
    ctx: CanvasRenderingContext2D,
    x: number,
    y: number,
    width: number,
    height: number,
    radius: number,
    corners: number,
    startAngle: number,
    fillStyle: string | CanvasGradient | CanvasPattern
  ) {
    ctx.beginPath();
    ctx.fillStyle = fillStyle;

    // const oldTransform = ctx.getTransform(); // not supported on n2
    //ctx.transform(1, 0, -skewFactor, 1, x, y);

    for (let i = 0; i < corners; i++) {
      const vx = x + radius * Math.cos((2 * Math.PI * i + startAngle) / corners);
      const vy = y + radius * Math.sin((2 * Math.PI * i + startAngle) / corners);
      if (i === 0) ctx.moveTo(vx, vy);
      else ctx.lineTo(vx, vy);
    }

    ctx.closePath();
    ctx.fill();
    // ctx.setTransform(oldTransform);
  }

  public static createNGonTexture(radius: number, corners: number, fill: string, startAngle: number = 0) {
    const canvas = document.createElement("canvas");
    canvas.width = radius * 2;
    canvas.height = radius * 2;
    const ctx = canvas.getContext("2d")!;
    DrawHelper.drawNGonGraphics(ctx, radius, radius, radius * 2, radius * 2, radius, corners, startAngle, fill);
    // const texture = PIXI.Texture.from(canvas);
    const baseTexture = new PIXI.BaseTexture(canvas); // don't cache
    const texture = new PIXI.Texture(baseTexture);
    return texture;
  }
}

const hm = module as any;
if (hm.hot) {
  hm.hot.dispose((d: any) => {
    DrawHelper.dispose();
  });
}

import * as PIXI from "pixi.js";
import { Group } from "../../Graphics/Group";
import { Logic, _t, _s, settings } from "../../Logic/Logic";
import { AnimHelper } from "../common/Anim";
import { IRoundInfo } from "../../Logic/LogicDefinitions";
import { MultiStyleText } from "../common/MultiStyleText";
import { Util } from "../../common/Util";
import { AnimatedBonusTopKart } from "./AnimatedBonusTopKart";
import { GameLength } from "../../common/Definitions";

export class NextRaceBarKart extends Group {
  private lapText: PIXI.Text;
  private timeText: MultiStyleText;
  private timeUntilText: PIXI.Text;
  public blurFilter = new PIXI.filters.BlurFilter();
  private animatedBonus: AnimatedBonusTopKart;
  private gameLength: GameLength;
  private language: string;
  private raceIdText: PIXI.Text;
  private forPauseOverlay: boolean;

  public constructor(gameLength: GameLength, language: string, forPauseOverlay: boolean) {
    super();

    this.forPauseOverlay = forPauseOverlay;

    this.gameLength = gameLength;
    this.language = language;
    this.showDebug(settings.debug);

    if (language === "it") {
      const style = new PIXI.TextStyle({
        fontFamily: "DIN-UltraLight",
        fontSize: _s(18),
        fill: "white",
        align: "right"
      });
      this.lapText = Logic.createPixiText(style);
      this.lapText.filters = [this.blurFilter];
      this.lapText.anchor.set(1.0, 0.0);
      this.add(this.lapText);
    } else {
      const style = new PIXI.TextStyle({
        fontFamily: "DIN-Light",
        fontSize: _s(30),
        fill: "white",
        align: "center"
      });
      this.lapText = Logic.createPixiText(style);
      this.lapText.filters = [this.blurFilter];
      this.add(this.lapText);
    }

    {
      const style = new PIXI.TextStyle({
        fontFamily: "DIN-UltraLightItalic",
        fontSize: _s(11),
        fill: "white",
        align: "left",
        fontStyle: "italic"
      });
      this.raceIdText = Logic.createPixiText(style);
      this.raceIdText.anchor.set(1, 0.5);
      if (language === "it") this.add(this.raceIdText);
    }

    {
      this.timeText = new MultiStyleText();
      this.timeText.anchor.set(1.0, 0.5);
      this.timeText.filters = [this.blurFilter];
      this.add(this.timeText);
    }

    {
      const style = new PIXI.TextStyle({
        fontFamily: "DIN-UltraLight",
        fontSize: _s(18),
        fill: "#FFF",
        align: "right"
      });
      this.timeUntilText = Logic.createPixiText(style);
      this.timeUntilText.anchor.set(1, 0.5);
      this.add(this.timeUntilText);
    }

    this.animatedBonus = new AnimatedBonusTopKart(gameLength);
    this.add(this.animatedBonus);
  }

  public fill(round: IRoundInfo | undefined, language: string) {
    if (round) this.animatedBonus.fill(round);

    if (round) {
      if (language === "it") {
        this.fillRound(_t("raceMotor").toUpperCase() + " " + Logic.implementation.formatRound(round.gameId), _t("sendPlanPre") + " " + round.sendPlan);
        Logic.autoSize(this.raceIdText, _s(110));
      } else {
        const split = round.sendPlan.split("_");
        this.fillRound(Logic.implementation.formatRound(round.gameId), split[0] + "_" + split[1] + "\n" + split[2]);
      }
    } else {
      this.fillRound("", "");
    }
  }

  private fillRound(round: string, raceId: string) {
    this.timeUntilText.text = _t("timeNextRa");
    Logic.autoSize(this.timeUntilText, _s(290));

    this.lapText.text = round;
    this.raceIdText.text = raceId;

    const style = new PIXI.TextStyle({
      fontFamily: "DIN-Bold",
      fontSize: _s(32),
      fill: "white",
      align: "center"
    });
    const red = new PIXI.TextStyle({
      fontFamily: "DIN-Heavy", // red appears smaller => use heavy
      fontSize: _s(32),
      fill: Util.rgbToHex(0xffff290e), // colors.red), // might be too dark?
      align: "center"
    });
    this.timeText.styles = { default: { ...style, valign: "middle" }, red };
  }

  public onLayout(): void {
    if (this.language === "it") this.lapText.position.y = _s(38);
    else this.lapText.position.y = _s(25);

    this.timeText.position.x = _s(292);
    this.timeText.position.y = _s(41);

    this.timeUntilText.x = _s(303); // _s(292);
    this.timeUntilText.y = _s(13);

    this.animatedBonus.x = _s(2);
    this.animatedBonus.y = _s(58);

    this.raceIdText.position.y = _s(32.0);
  }

  public setTimeUntilRace(timeUntilRace: number) {
    const counterText = Logic.implementation.formatTime(timeUntilRace);
    if (timeUntilRace < 10) {
      // last letter in red...
      /*const frontPart = counterText.substring(0, counterText.length - 1);
      const redPart = counterText.substring(counterText.length - 1, counterText.length);
      this.timeText.text = frontPart + "<red>" + redPart + "</red>";*/
      this.timeText.text = "<red>" + counterText + "</red>";
    } else {
      this.timeText.text = counterText;
    }
  }

  private updateTextPositions(fadeFactor: number) {
    if (this.language === "it") {
      this.lapText.position.x = _s(-65 + 188 * fadeFactor);
      this.raceIdText.position.x = _s(12 + 110 * fadeFactor);
    } else this.lapText.position.x = _s(-90 + 150 * fadeFactor);
  }

  public update(dt: number) {
    super.update(dt);

    if (this.forPauseOverlay) {
      this.timeText.alpha = 1.0;
      this.raceIdText.alpha = 1.0;
      this.lapText.alpha = 1.0;
      this.timeUntilText.alpha = 1.0;
      this.raceIdText.alpha = 1.0;
      this.updateTextPositions(1.0);
      this.setTimeUntilRace(10.23);
      this.blurFilter.enabled = false;
    } else {
      const startTime = 1.15;
      const duration = Logic.getIntroEndTime() - startTime + 0.001; //  168.5; // Logic.videoRef.switchToIntroAtTime !== undefined ? Logic.videoRef.switchToIntroAtTime : Logic.getIntroLength();

      const t = Logic.getVideoTime();
      const anims = [{ startTime, duration }];
      const anim = Logic.getAnim(t, anims, this);
      if (!anim || !Logic.isInIntro()) {
        this.visible = false;
        return;
      }
      this.visible = true;

      const baseFactor = t - anim.startTime;
      if (baseFactor >= anim.duration) {
        const f1 = AnimHelper.easeOut(AnimHelper.limit(baseFactor, anim.duration) * 4, 5);
        this.lapText.alpha = f1;
        this.timeText.alpha = f1;
        this.timeUntilText.alpha = f1;
        this.animatedBonus.alpha = f1;
        this.raceIdText.alpha = f1;
      } else {
        const f1 = AnimHelper.easeOut(AnimHelper.limit(baseFactor, anim.duration) * 4, 5);
        this.updateTextPositions(f1);
        this.lapText.alpha = f1;

        this.timeText.alpha = f1;
        this.raceIdText.alpha = f1;
        const newBlur = 4 * (1.0 - f1);
        if (this.blurFilter.blur !== newBlur) {
          this.blurFilter.blur = newBlur;
          this.blurFilter.enabled = newBlur > 0;
        }

        const timeUntilRace = Logic.getTimeUntilRaceForTimeBar() - Logic.getVideoTime(); // INTRO_VIDEO_LENGTH
        this.setTimeUntilRace(timeUntilRace);

        this.timeUntilText.alpha = f1;
        this.animatedBonus.alpha = f1;
      }
    }
  }
}

import * as PIXI from "pixi.js";
import { Group } from "../../Graphics/Group";
import { _s, settings, Logic, _t } from "../../Logic/Logic";
import { AnimHelper } from "../common/Anim";
import { DogHelper } from "./DogHelper";
import { GameType } from "../../common/Definitions";

export class TrackPresentationLapInfo extends Group {
  private line1: PIXI.Text;
  private line2: PIXI.Text;
  public fadeInTime: number = 0;
  public fadeOutTime: number = Number.MAX_VALUE;
  public anims: any[] = [];
  private oddsAlwaysOn: boolean;
  private gameType: GameType;
  public constructor(oddsAlwaysOn = false, gameType: GameType) {
    super();

    this.oddsAlwaysOn = oddsAlwaysOn;
    this.gameType = gameType;
    this.showDebug(settings.debug, 1, "TrackPresentationLapInfo");

    const line1Style = new PIXI.TextStyle({
      fontFamily: "DIN-BoldItalic",
      fontSize: _s(12),
      fill: DogHelper.getBlackColor(),
      align: "right",
      fontStyle: "italic"
    });

    const line2Style = new PIXI.TextStyle({
      fontFamily: "DIN-BoldItalic",
      fontSize: _s(24),
      fill: DogHelper.getWhiteColor(),
      align: "right",
      fontStyle: "italic"
    });

    if (this.oddsAlwaysOn) {
      line1Style.fontSize = _s(8);
      line2Style.fontSize = _s(16);
      line2Style.fontFamily = "DIN-MediumItalic";
    }

    this.line1 = Logic.createPixiText(line1Style);
    this.line1.anchor.set(1, 0.5);
    this.add(this.line1);

    this.line2 = Logic.createPixiText(line2Style);
    this.line2.anchor.set(1, 0.5);
    this.add(this.line2);

    this.line1.text = "AVG TIME:";
    this.line2.text = "30 secs";
  }

  public onLayout() {
    this.line1.x = _s(138);
    this.line1.y = _s(8);

    this.line2.x = _s(132);
    this.line2.y = _s(32);

    if (this.oddsAlwaysOn) {
      if (this.gameType === "dog8") {
        this.line1.y = _s(5);
      } else {
        this.line1.y = _s(6);
      }
      this.line2.y = _s(22);
    }
  }

  public update(dt: number) {
    super.update(dt);

    const t = Logic.getVideoTime();
    const anim = Logic.getAnim(t, this.anims, this);
    if (!anim) return;

    const baseFactor = t - anim.startTime;
    let line1Width = 120;
    if (this.oddsAlwaysOn) line1Width = 84;

    if (anim.key !== this.line1.text) {
      this.line1.text = anim.key;
      Logic.autoSize(this.line1, _s(line1Width));
    }
    if (anim.value !== this.line2.text) {
      this.line2.text = anim.value;
      Logic.autoSize(this.line2, _s(line1Width));
    }

    let xAnimOffset1 = 138;
    let xAnimOffset2 = 132;

    AnimHelper.animateInOut(baseFactor, 0, anim.duration, 0.3, 0, 1, (val) => (this.line1.alpha = val * val), 0.3, 0);
    AnimHelper.animateInOut(baseFactor, 0.2, anim.duration, 0.3, 0, 1, (val) => (this.line2.alpha = val * val), 0.3, 0);

    if (this.oddsAlwaysOn) {
      xAnimOffset1 = 133;
      xAnimOffset2 = 127;
    }

    AnimHelper.animateInOut(baseFactor, 0, anim.duration, 0.3, 70, xAnimOffset1, (val) => (this.line1.x = _s(val)), 0.3, 0);
    AnimHelper.animateInOut(baseFactor, 0.2, anim.duration, 0.3, 70, xAnimOffset2, (val) => (this.line2.x = _s(val)), 0.3, 0);
  }
}

// import type { IRtcEnvelope, RtcMessage, GetMessageResponseType, IErrorResponseMessage } from "./RtcDefinitions";

interface IRtcEnvelope {
  messageId: number;
  responseId?: number;
  responseExpected: boolean;
  message: RtcMessage;
}

interface RtcMessage {
  type: string;
  message?: string;
}

interface IErrorResponseMessage extends RtcMessage {
  error: any;
}

type GetMessageResponseType<T extends RtcMessage> = T extends { type: "Error" } ? IErrorResponseMessage : any;

interface IPendingResponse {
  id: number;
  callback: (response?: any, error?: any) => void;
}

interface RtcWebSocket {
  send: (data: string) => void;
}

export class RtcSocket<WS extends RtcWebSocket> {
  public id: string;
  public roomId: string;
  private messageId: number = 1;
  private pendingResponses: IPendingResponse[] = [];
  public ws: WS;

  public constructor(ws: WS, id: string, roomId: string) {
    this.ws = ws;
    this.id = id;
    this.roomId = roomId;
  }

  public onResponse(envelope: IRtcEnvelope): void {
    if (envelope.responseId) {
      const index = this.pendingResponses.findIndex((pr) => pr.id === envelope.responseId);
      if (index !== -1) {
        const item = this.pendingResponses.splice(index, 1);
        const callback = item[0].callback;
        if (!callback) {
          console.log("Warning! - no callback: " + envelope.message?.type + " " + envelope.messageId);
          return;
        }
        if (envelope.message.type === "Error") callback(undefined, envelope.message);
        else callback(envelope.message);
      }
    }
  }

  private sendMessageInternal<T extends RtcMessage>(msg: T, responseId: number | undefined, callback?: (responseData: GetMessageResponseType<T>, error?: any) => void) {
    const mid = this.messageId++;
    const envelope: IRtcEnvelope = {
      messageId: mid,
      responseId,
      responseExpected: callback !== undefined,
      message: msg
    };
    console.log("SendMessage: " + msg.type + " to " + this.id + "/" + this.roomId + " " + new Date().toTimeString());
    this.ws.send(JSON.stringify(envelope));
    if (callback !== undefined) {
      this.pendingResponses.push({ id: mid, callback });
    }
  }

  public sendMessage<T extends RtcMessage>(msg: T, callback?: (responseData: GetMessageResponseType<T>, error?: any) => void): void {
    this.sendMessageInternal(msg, undefined, callback);
  }

  public sendResponse<T extends RtcMessage>(msg: T, responseId: number, callback?: (responseData: GetMessageResponseType<T>, error?: any) => void): void {
    this.sendMessageInternal(msg, responseId, callback);
  }

  public sendResponseError(message: string, error: any | undefined, responseId: number, callback?: (responseData: GetMessageResponseType<IErrorResponseMessage>, _error?: any) => void): void {
    this.sendMessageInternal({ type: "Error", message, error }, responseId, callback);
  }

  public async sendMessageWait<T extends RtcMessage>(msg: T): Promise<GetMessageResponseType<T>> {
    return new Promise<GetMessageResponseType<T>>((resolve, reject) => {
      this.sendMessage(msg, (rd, error) => {
        if (error) reject(error);
        else resolve(rd);
      });
    });
  }

  public async sendResponseWait<T extends RtcMessage>(msg: T, responseId: number): Promise<GetMessageResponseType<T>> {
    return new Promise<GetMessageResponseType<T>>((resolve, reject) => {
      this.sendResponse(msg, responseId, (rd, error) => {
        if (error) reject(error);
        else resolve(rd);
      });
    });
  }

  public clearPendingResponses(): void {
    this.pendingResponses = [];
  }
}

import * as PIXI from "pixi.js";
import { Group } from "../../../Graphics/Group";
import { Logic, _s, _t, settings } from "../../../Logic/Logic";
import { AnimHelper } from "./../../common/Anim";
import { IRoundHistory, IDriver, IAnimInterval, IDog63RoundHistory, IDog63SuprimiEntry, IDog63QuoteInfo } from "../../../Logic/LogicDefinitions";
//import { BonusAnnotationDog } from "./BonusAnnotationDog";
import { MultiStyleText } from "./../../common/MultiStyleText";
import { DrawHelper } from "../../common/DrawHelper";
import { GameType, GameLength } from "../../../common/Definitions";
import { LayoutHelper } from "../../../VideoScreen/Util/LayoutHelper";
import { DiagonalFadeHelper } from "../../../VideoScreen/common/DiagonalFadeHelper";
import { WinnerDogBase } from "./WinnerDogBase";

export class WinnerDog3 extends WinnerDogBase {
  public constructor(gameType: GameType, gameLength: GameLength) {
    super(gameType, gameLength, 9, 16, 14, 20, 20, 0.745);
    this.showDebug(settings.debug, undefined, "WinnerDog3");
  }

  // public createAnims(gameType: GameType, gameLength: GameLength, withBonus: boolean): IAnimInterval[] {
  //   {
  //     return  [
  //       { startTime: 10, duration: 50.8 },
  //     ]
  //   }
  // }

  public override fill(withBonus: boolean, driverNumber: number, driver: IDriver, driverTime: string, quotes: IDog63QuoteInfo[]): void {
    super.fill(withBonus, driverNumber, driver, driverTime, quotes);

    this.header.text = _t("thirdTxt");
    this.quoteHeaders[0].text = _t("numberSignThree");
    this.quoteHeaders[1].text = _t("winFiSecThi");
    Logic.autoSize(this.quoteHeaders[1], _s(62));
    this.quoteHeaders[2].visible = false;
  }

  public override onLayout(): void {
    super.onLayout();

    this.header.y = _s(4);
    this.bar.x = _s(278) * this.scaleFactor;
    this.driverNumber.x = _s(271 * this.scaleFactor);

    this.quoteHeaders[0].visible = true; // turn it off for dog 1
    this.quoteHeaders[0].x = _s(287);
    this.quoteHeaders[0].y = _s(40);
    this.quotes[0].x = _s(340);
    this.quotes[0].y = _s(53);
    this.quotes[1].x = _s(332);
  }

  public override updateAnim(baseFactor: number, duration: number) {
    super.updateAnim(baseFactor, duration);
    DiagonalFadeHelper.FadeDiagonal(this, baseFactor + 0.05, duration, 0.9, 0.3, 1, Logic.videoScreen.width, Logic.videoScreen.height);
  }
}

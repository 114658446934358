import * as PIXI from "pixi.js";
import { Group } from "../../../Graphics/Group";
import { Logic, _s, _t, settings } from "../../../Logic/Logic";
import { AnimHelper } from "./../../common/Anim";
import { IRoundHistory, IDriver, IAnimInterval, IDog63RoundHistory, IDog63SuprimiEntry, IDog63QuoteInfo } from "../../../Logic/LogicDefinitions";
import { GameType, GameLength } from "../../../common/Definitions";
import { WinnerDogBase } from "./WinnerDogBase";
import { DiagonalFadeHelper } from "../../../VideoScreen/common/DiagonalFadeHelper";

export class WinnerDog2 extends WinnerDogBase {
  //private anims: (IAnimInterval & {fadeInFactor?: number; fadeOutFactor?: number})[] = [];

  public constructor(gameType: GameType, gameLength: GameLength) {
    super(gameType, gameLength, 10, 18, 16, 24, 24, 0.86);
    this.showDebug(settings.debug, undefined, "WinnerDog2");
  }

  // public createAnims(gameType: GameType, gameLength: GameLength, withBonus: boolean): IAnimInterval[] {
  //   {
  //     return  [
  //       { startTime: 10, duration: 50.8 },
  //     ]
  //   }
  // }

  public override fill(withBonus: boolean, driverNumber: number, driver: IDriver, driverTime: string, quotes: IDog63QuoteInfo[]): void {
    super.fill(withBonus, driverNumber, driver, driverTime, quotes);

    this.header.text = _t("second");
    this.quoteHeaders[0].text = _t("numberSignTwo");
    this.quoteHeaders[1].text = _t("winFiSec");
    this.quoteHeaders[2].text = _t("winFiSecThi");
    Logic.autoSize(this.quoteHeaders[2], _s(68));
  }

  public override onLayout(): void {
    super.onLayout();

    this.header.y = _s(5);

    this.quoteHeaders[0].visible = true; // turn it off for dog 1
    this.quoteHeaders[0].x = _s(332);
    this.quoteHeaders[0].y = _s(45);
    this.quotes[0].x = _s(392);
    this.quotes[0].y = _s(60);
    this.quotes[1].x = _s(382);
    this.quotes[2].x = _s(376);
  }

  public override updateAnim(baseFactor: number, duration: number) {
    super.updateAnim(baseFactor, duration);
    DiagonalFadeHelper.FadeDiagonal(this, baseFactor + 0, duration, 0.9, 0.3, 1, Logic.videoScreen.width, Logic.videoScreen.height);
  }
}

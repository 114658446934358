export class Settings {
  public static versionNumber = "1.014.06";

  //public static defaultSocketUrl = "wss://vgcontrol.com:1223/tv";
  public static defaultSocketUrl = "ws://localhost:8080";
  public static socketUrls = [
    {
      domain: "localhost:65336",
      // socketUrl: "wss://vgcontrol.com:1223/tv"
      socketUrl: "wss://office.racingdogs.eu:1223/tv"
    },
    {
      domain: "192.168.8.218:65336",
      // socketUrl: "wss://vgcontrol.com:1223/tv"
      socketUrl: "wss://office.racingdogs.eu:1223/tv"
    },
    {
      domain: "192.168.0.6:65336",
      socketUrl: "ws://localhost:8080"
      // socketUrl: "wss://office.racingdogs.eu:1223/tv"
    },
    {
      domain: "video3.racingdogs.eu",
      socketUrl: "ws://localhost:10222/tv"
    }
  ];
  public static noResultWaitSeconds = 10;
  public static kickboxQuotaDecimalPlaces = 2;
  public static onlineRetryTime = 60000; //milliseconds
  public static socketClosedRetryTime = 10000; //milliseconds
}

interface ISocketUrls {
  domain: string;
  socketUrl: string;
}

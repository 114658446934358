import { MediaSoupClient } from "./MediaSoupClient";
import { Logger } from "../Logic/Logger";

export class RtcLogic {
  public id!: string;
  public roomId!: string;
  public rtcClient?: MediaSoupClient;
  public meta?: any;

  private constructor() { }

  public init(id: string, roomId: string | undefined, type: string | undefined, meta?: any): void {
    this.id = id;
    if (!roomId) roomId = "room1";
    this.roomId = roomId;
    this.meta = meta;
    this.rtcClient = new MediaSoupClient(id, roomId, type === "producer" ? "producer" : "consumer", meta);
  }

  private static internalInstance: RtcLogic;
  static get instance(): RtcLogic {
    if (this.internalInstance == null) this.internalInstance = new RtcLogic();
    return this.internalInstance;
  }

  public async connectRtcClient(): Promise<boolean> {
    if (!this.rtcClient) return false;
    const ip4 = window.location.hostname;
    const port = window.location.port ? window.location.port : 443;
    const wsProtocol = window.location.protocol.startsWith("https:") ? "wss:" : "ws:";
    // const port = window.location.protocol.startsWith("http:") ? 65336 : 65336;
    let wsUrl = wsProtocol + "//" + ip4 + ":" + port + "/ws";
    console.log("WS: " + wsUrl);

    if (wsUrl === "ws://:443/ws") {
      wsUrl = "wss://video-aws2.racingdogs.eu:443/ws";
      console.log("WSb: " + wsUrl);
    }

    try {
      await this.rtcClient.open(wsUrl);
    } catch (e: any) {
      console.log("connectRtcClient", e);
      Logger.error("connectRtcClient: ", e);
      return this.connectRtcClient();
    }
    return false;
  }

  public isProducer(): boolean | undefined {
    return this.rtcClient && this.rtcClient.getType() === "producer";
  }

  public isPlayer(): boolean {
    return !this.rtcClient;
  }

  public isConsumer(): boolean | undefined {
    return this.rtcClient && this.rtcClient.getType() === "consumer";
  }
}

import * as PIXI from "pixi.js";
import { Group } from "../../Graphics/Group";
import { _s, settings, Logic } from "../../Logic/Logic";
import { MultiStyleText, ITextStyleSet } from "./../common/MultiStyleText";
import { IAnimInterval } from "../../Logic/LogicDefinitions";
import { AnimHelper } from "../common/Anim";
import { DogHelper } from "./DogHelper";
import { GameType } from "../../common/Definitions";

// TrackPresentation Circle times in the center
// TODO: fade in
// TODO: update color to red after specific time
export class TrackPresentationTimesCirlce extends Group {
  public fadeInTime: number = 0;
  public fadeOutTime: number = Number.MAX_VALUE;
  private oddsAlwaysOn;
  public times: PIXI.Text[] = [];

  private anims: IAnimInterval[] = [];

  public constructor(gameType: GameType, oddsAlwaysOn = false) {
    super();
    this.oddsAlwaysOn = oddsAlwaysOn;
    this.showDebug(settings.debug);

    const isHorse = gameType === "horse" || gameType === "sulky";

    const timesCount = 7;
    let angleSpacing = isHorse ? 0.49 : 0.48;
    let radius = isHorse ? 38 : 45;
    const startAngle = isHorse ? -2.3 : -2.2;

    const timesStyle = new PIXI.TextStyle({
      fontFamily: "DIN-BoldItalic",
      fontSize: _s(isHorse ? 12 : 14),
      fill: DogHelper.getWhiteColor(),
      align: "right",
      fontStyle: "italic"
    });
    if (this.oddsAlwaysOn) {
      angleSpacing = 0.46;
      radius = 32.4;
      timesStyle.fontSize = _s(10);
    }
    for (let i = 0; i < timesCount; i++) {
      const x = radius * Math.cos(startAngle + i * angleSpacing);
      const y = radius * Math.sin(startAngle + i * angleSpacing);

      const text = Logic.createPixiText(timesStyle);
      text.anchor.set(0.5, 0.5);

      text.text = (i * (isHorse ? 15 : 5)).toString();
      this.add(text);
      text.position.x = _s(x);
      text.position.y = _s(y);

      if (isHorse) {
        if (i === 3) {
          text.position.x += _s(2);
          text.position.y += _s(1);
        } else if (i === 4) {
          text.position.x += _s(1);
          text.position.y += _s(3);
        } else if (i === 5) {
          text.position.x += _s(0);
          text.position.y += _s(2);
        } else if (i === 6) {
          text.position.x += _s(0);
          text.position.y += _s(1);
        }
      }
      if (this.oddsAlwaysOn) {
        if (i === 3 || i === 4) {
          text.position.x += _s(2);
        }
      }
      this.times.push(text);
    }
  }

  public fill(anims: IAnimInterval[]) {
    this.anims = anims;

    // {
    //   const defaultStyle: IExtendedTextStyle = {
    //     fontFamily: "DIN-UltraLightItalic",
    //     fontSize: _s(10),
    //     letterSpacing: _s(1),
    //     fill: "red",
    //     valign: "middle",
    //     maxLines: 1,
    //     wordWrap: true,
    //     wordWrapWidth: _s(90),
    //     fontStyle: "italic"
    //   };
    // }
  }

  private updateText(multiText: MultiStyleText, text: string | undefined, styles?: ITextStyleSet) {
    if (text) {
      if (styles) multiText.styles = styles;
      multiText.text = text;
      multiText.visible = true;
    } else {
      multiText.visible = false;
    }
  }

  public onLayout() {
    // this.line1.x = _s(0);
    // this.line1.y = _s(0);
  }

  public update(dt: number) {
    super.update(dt);

    const t = Logic.getVideoTime();
    const anim = Logic.getAnim(t, this.anims, this);
    if (!anim) {
      this.visible = false;
      return;
    }
    this.visible = true;

    const baseFactor = t - anim.startTime;

    // AnimHelper.animateIn(baseFactor, 0, 3, 1, 0, 1, (val) => this.line1.alpha = val);
    // AnimHelper.animateIn(baseFactor, 0, 3, 0.4, -20, 18, (val) => this.line1.y = val);
    // AnimHelper.animateIn(baseFactor, 0.8, 2, 1, 1, 0.5, (val) => this.line1.alpha = val);
    // AnimHelper.animateIn(baseFactor, 0, 3, 0.4, 0, 1, (val) => this.lapText.alpha = val);
    // AnimHelper.animateIn(baseFactor, 0, 3, 0.4, 0, 1, (val) => this.lapNumberText.alpha = val);
    for (let i = 0; i < this.times.length; i++) {
      AnimHelper.animateInOut(baseFactor, 0, anim.duration, 1, 0, 1, (val) => (this.times[i].alpha = val), 0, 0);
      AnimHelper.animateIn(baseFactor, 0.5 + i * (2.3 / 7), 10, 0.3, 1, 0, (val) => (this.times[i].tint = 0xff0000 | (val * 0xff) | ((val * 0xff00) & 0xff00)));
    }
  }
}

import * as React from "react";
import { asComponent } from "./ReactHelper";
import { makeStyles } from "@material-ui/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import { IDownloadProgress } from "../Update/LocalCache";
import { observable } from "mobx";

export interface IDownloadProgressObject {
  loading: boolean;
  files: IDownloadProgress[];
}
export const downloadObject = observable({ loading: false, files: [] } as IDownloadProgressObject);

const useStyles = makeStyles({
  updateOverlay: {
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "#EEEEEE",
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    justifyContent: "center"
  },
  text: {
    margin: "5px"
  },
  text2: {
    margin: "5px",
    fontSize: "85%"
  },
  progress: {
    margin: "10px"
  }
});

function calcBytes() {
  let loaded = 0;
  let total = 0;
  for (const d of downloadObject.files) {
    loaded += d.loaded;
    total += d.total;
  }
  return { loaded, total };
}

function formatBytes(bytes: number) {
  return Math.floor(bytes / 1024);
}

export const UpdatePage = asComponent("UpdatePage", () => {
  const styles = useStyles();
  const dl = calcBytes();
  return (
    <div className={styles.updateOverlay}>
      <div className={styles.text}>UpdatePage</div>
      <LinearProgress className={styles.progress} />
      <div className={styles.text2}>{formatBytes(dl.loaded) + "Kb/" + formatBytes(dl.total) + "Kb"}</div>
    </div>
  );
});

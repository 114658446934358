import * as React from "react";
import * as ReactDOM from "react-dom";
import { createRoot } from 'react-dom/client';

import { Logger } from "./Logic/Logger";

export class MainHelpers {
  public static resetOnCrashHandler?: () => void;
  public static render<T>(Component: any) {
    const root = createRoot(document.getElementById("root")!);

    try {
      root.render(<Component />);
    } catch (e) {
      Logger.error("MainHelpers.render: ", e);
      if (MainHelpers.resetOnCrashHandler) MainHelpers.resetOnCrashHandler();

      root.render(<Component />);
    }
  }
}

import { Group } from "../../Graphics/Group";
import { _s } from "../../Logic/Logic";
import { MultiStyleText, ITextStyleSet, IExtendedTextStyle } from "../common/MultiStyleText";
import { ITrackItem } from "../../Logic/LogicDefinitions";

export class TurnItemKart extends Group {
  private line1: MultiStyleText;
  private line2: MultiStyleText;
  private curveType: MultiStyleText;
  private interval: MultiStyleText;
  public fadeInTime: number = 0;
  public fadeOutTime: number = Number.MAX_VALUE;

  public constructor() {
    super();

    this.line1 = new MultiStyleText();
    this.add(this.line1);

    this.line2 = new MultiStyleText();
    this.add(this.line2);

    this.curveType = new MultiStyleText();
    // this.curveType.align
    this.add(this.curveType);

    this.interval = new MultiStyleText();
    this.add(this.interval);
  }

  public fill(trackItem: ITrackItem) {
    {
      const defaultStyle: IExtendedTextStyle = {
        fontFamily: "DIN-UltraLight",
        fontSize: _s(13),
        letterSpacing: _s(1),
        fill: "white",
        valign: "middle",
        maxLines: 1,
        wordWrap: true,
        wordWrapWidth: _s(90)
      };
      const boldStyle: IExtendedTextStyle = {
        fontFamily: "DIN-Medium",
        fontSize: _s(13),
        letterSpacing: _s(1),
        fill: "white",
        valign: "middle"
      };
      this.updateText(this.line1, trackItem.line1, { default: defaultStyle, b: boldStyle });
    }
    {
      const defaultStyle: IExtendedTextStyle = {
        fontFamily: "DIN-UltraLight",
        fontSize: _s(9),
        maxLines: 2,
        letterSpacing: _s(1),
        fill: "white",
        wordWrap: true,
        wordWrapWidth: _s(85),
        valign: "middle",
        lineHeight: _s(8)
      };
      const boldStyle: IExtendedTextStyle = {
        fontFamily: "DIN-Medium",
        fontSize: _s(9),
        maxLines: 1,
        letterSpacing: _s(1),
        fill: "white",
        valign: "middle"
      };
      this.updateText(this.line2, trackItem.line2, { default: defaultStyle, b: boldStyle });
    }
    {
      const defaultStyle: IExtendedTextStyle = {
        fontFamily: "DIN-Medium",
        fontSize: _s(13),
        fill: "white",
        align: "left",
        valign: "middle",
        maxLines: 1
      };
      const boldStyle: IExtendedTextStyle = {
        fontFamily: "DIN-Black",
        fontSize: _s(13),
        fill: "white",
        align: "left",
        valign: "middle",
        maxLines: 1
      };
      this.updateText(this.interval, trackItem.interval, { default: defaultStyle, b: boldStyle });
    }
    {
      const defaultStyle: IExtendedTextStyle = {
        fontFamily: "DIN-Medium",
        fontSize: _s(9),
        fill: "black",
        align: "right",
        valign: "middle"
      };
      const boldStyle: IExtendedTextStyle = {
        fontFamily: "DIN-Black",
        fontSize: _s(9),
        fill: "black",
        align: "right",
        valign: "middle"
      };
      this.updateText(this.curveType, trackItem.curveType, { default: defaultStyle, b: boldStyle });
    }
    this.interval.anchor.y = 0.5;
    this.line1.anchor.y = 0.5;
    this.line2.anchor.y = 0.5;
    this.curveType.anchor.y = 0.5;
    this.curveType.anchor.x = 1.0;
    // this.interval.setAnchor(0, 0.5);
    // this.line1.setAnchor(0, 0.5);
    // this.line2.setAnchor(0, 0.5);
    // this.curveType.align = "right";

    this.onLayout();
  }

  private updateText(multiText: MultiStyleText, text: string | undefined, styles?: ITextStyleSet) {
    if (text) {
      if (styles) multiText.styles = styles;
      multiText.text = text;
      multiText.visible = true;
    } else {
      multiText.visible = false;
    }
  }

  public onLayout() {
    this.line1.x = _s(0);
    this.line1.y = _s(0);
    this.line2.x = _s(0);
    this.line2.y = _s(19);
    this.interval.x = _s(0);
    this.interval.y = _s(-17);
    this.curveType.x = _s(100);
    this.curveType.y = _s(34.5);
  }
}

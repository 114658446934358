import { Graphics } from "pixi.js";
import { Group } from "../../Graphics/Group";
import { _s, settings, Logic } from "../../Logic/Logic";
import { MultiStyleText, ITextStyleSet, IExtendedTextStyle } from "../common/MultiStyleText";
import { AnimHelper } from "../common/Anim";
import { IAnimInterval } from "../../Logic/LogicDefinitions";
import { DogHelper } from "./DogHelper";
// top right lap info for trackpresentation
// TODO: fade in/out
// TODO: change Text accordingly
// TODO: clip with roundedskewedRectangle mask?
export class TrackPresentationLapMapFact extends Group {
  private line1: MultiStyleText;
  public fadeInTime: number = 0;
  public fadeOutTime: number = Number.MAX_VALUE;
  private anims: IAnimInterval[] = [];
  private alignLeft: boolean;
  private mask: Graphics = new Graphics();
  private availableWidth: number;
  private oddsAlwaysOn;
  public constructor(anims: IAnimInterval[], alignLeft: boolean, availablewidth: number, oddsAlwaysOn = false) {
    super();
    this.oddsAlwaysOn = oddsAlwaysOn;
    this.alignLeft = alignLeft;
    this.anims = anims;
    this.availableWidth = availablewidth;

    this.showDebug(settings.debug);

    this.line1 = new MultiStyleText();
    this.line1.anchor.set(alignLeft === true ? 0.0 : 1.0, 0.5);
    this.add(this.line1);
  }

  public fill(lapMapFact: string, availableWidth: number) {
    const showDebugTextCol = settings.showDebugTextColor;
    const defaultStyle: IExtendedTextStyle = {
      fontFamily: "DIN-UltraLightItalic",
      fontSize: _s(20),
      fill: showDebugTextCol ? "orange" : DogHelper.getWhiteColor(),
      valign: "middle",
      align: "center",
      maxLines: 1,
      wordWrap: true,
      wordWrapWidth: _s(availableWidth), // _s(90)
      fontStyle: "italic"
    };

    const boldStyle: IExtendedTextStyle = {
      fontFamily: "DIN-Bold",
      fontSize: _s(20),
      letterSpacing: _s(1),
      align: "center",
      fill: showDebugTextCol ? "orange" : DogHelper.getWhiteColor(),
      valign: "middle"
    };
    if (this.oddsAlwaysOn) {
      defaultStyle.fontSize = _s(14);
      boldStyle.fontSize = _s(14);
    }

    // add mask to text
    this.mask = Logic.createPixiMask(0, 4, availableWidth + 20, 20, true);
    this.add(this.mask);
    this.line1.mask = this.mask;

    this.updateText(this.line1, lapMapFact, { default: defaultStyle, b: boldStyle });
  }

  private updateText(multiText: MultiStyleText, text: string | undefined, styles?: ITextStyleSet) {
    if (text) {
      if (styles) multiText.styles = styles;
      multiText.textSteps = 30;
      multiText.text = text;
      multiText.visible = true;
    } else {
      multiText.visible = false;
    }
  }

  public onLayout(): void {
    this.line1.x = this.alignLeft ? _s(0) : _s(this.availableWidth + 20);
    this.line1.y = _s(15);
  }

  public update(dt: number): void {
    super.update(dt);

    const currentVideoTime = Logic.getVideoTime();
    const anim = Logic.getAnim(currentVideoTime, this.anims, this);

    if (!anim) {
      this.visible = false;
      return;
    }
    this.visible = true;

    const relativeStartTime = currentVideoTime - anim.startTime;
    AnimHelper.animateInOutDifferentParameters(
      relativeStartTime,
      0,
      anim.duration,
      0.6,
      0,
      1,
      (val) => (this.line1.alpha = val),
      0.6,
      0,
      -this.line1.width - 30,
      (val) => (this.mask.x = val)
    );
    if (currentVideoTime < anim.startTime + anim.duration - 1) {
      AnimHelper.animateIn(relativeStartTime, 0, anim.duration, 0.5, 0, 1, (val) => (this.line1.scale.x = val));
    }
  }
}

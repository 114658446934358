import { DogHelper } from "./DogHelper";
import { Util } from "../../common/Util";
import * as PIXI from "pixi.js";
import { Group } from "../../Graphics/Group";
import { _s, Logic, _t, settings } from "../../Logic/Logic";
import { AnimHelper } from "./../common/Anim";
import { IRoundInfo, IAnimInterval } from "../../Logic/LogicDefinitions";
import { AnimatedNumber } from "./../common/AnimatedNumber";
import { GameType, GameLength } from "../../common/Definitions";

export class AnimatedBonusBarDog extends Group {
  private animatedNumber: AnimatedNumber;
  private text: PIXI.Text;
  private hasBonus = false;
  private anims: IAnimInterval[] = [];
  private oddsAlwaysOn;

  public constructor(gameType: GameType, gameLength: GameLength, oddsAlwaysOn = false) {
    super();
    this.oddsAlwaysOn = oddsAlwaysOn;
    this.anims = this.createAnims(gameType, gameLength);
    this.showDebug(settings.debug, 1, "AnimatedBonusBarDog");

    const bonusStyle = new PIXI.TextStyle({
      fontFamily: "DIN-Medium",
      fontSize: _s(13),
      letterSpacing: this.oddsAlwaysOn ? _s(1) : _s(2),
      fill: "white",
      align: "right"
    });

    this.animatedNumber = new AnimatedNumber(bonusStyle);
    this.add(this.animatedNumber);

    const style = new PIXI.TextStyle({
      fontFamily: "DIN-UltraLightItalic",
      fontSize: _s(30),
      fill: "white",
      align: "center",
      fontStyle: "italic"
    });

    if (this.oddsAlwaysOn) style.fontSize = _s(26);

    this.text = Logic.createPixiText(style);
    this.text.anchor.set(0, 0.5);
    this.add(this.text);
  }

  private createAnims(gameType: GameType, gameLength: GameLength): IAnimInterval[] {
    if (this.oddsAlwaysOn) {
      return [{ startTime: 148.1, duration: 14.0 }];
    }
    if (gameType === "horse") {
      switch (gameLength) {
        case 320:
          return [{ startTime: 151.5, duration: 7.3 }];
      }
    }

    if (gameType === "sulky") {
      return [{ startTime: 149.4, duration: 9.5 }];
    }

    if (gameType === "dog6") {
      switch (gameLength) {
        case 120:
          return [{ startTime: 26.8, duration: 7.3 }];
        case 180:
          return [{ startTime: 81.1, duration: 8.5 }];
        case 240:
          return [{ startTime: 136.5, duration: 12.7 }];
        case 300:
          return [{ startTime: 136.5, duration: 17.3 }];
      }
      return [{ startTime: 139.7, duration: 10 }];
    }

    if (gameType === "dog63") {
      switch (gameLength) {
        case 300:
          return [{ startTime: 158.4, duration: 17.6 }];
      }
      return [{ startTime: 158.4, duration: 17.6 }];
    } else {
      // dog8
      switch (gameLength) {
        case 120:
          return [{ startTime: 25.8, duration: 9.0 }];
        case 180:
          return [{ startTime: 81.1, duration: 7.9 }];
        case 240:
          return [{ startTime: 141.0, duration: 13.3 }];
        case 300:
          return [{ startTime: 141.0, duration: 17.8 }];
      }
      return [{ startTime: 146.8, duration: 156.0 - 146.8 }];
    }
  }

  public fill(roundInfo: IRoundInfo) {
    this.text.text = _t("curBonus");
    Logic.autoSize(this.text, _s(400));

    this.animatedNumber.fill(roundInfo.jackpotValue, roundInfo.oldJackpotValue);
    this.hasBonus = roundInfo.jackpotValue !== undefined;
  }

  public onLayout() {
    this.text.y = this.height * 0.5;
    this.animatedNumber.position.y = this.height * 0.5;

    this.animatedNumber.setFontSize(_s(this.oddsAlwaysOn ? 26 : 30));
  }

  public update(dt: number) {
    super.update(dt);

    if (!this.hasBonus) {
      this.alpha = 0.0;
      return;
    }
    this.alpha = 1.0;

    const t = Logic.getVideoTime();
    const anim = Logic.getAnim(t, this.anims, this);
    if (!anim) return;

    const baseFactor = t - anim.startTime;
    const delta = 0.15;
    AnimHelper.animateInOut(baseFactor + delta, 0, anim.duration, 1.0, 0, 1, (val) => (this.text.alpha = val), 0.5, 0);
    AnimHelper.animateInOut(baseFactor + delta, 0, anim.duration, 1.0, 0, 1, (val) => (this.text.x = _s(15) - _s(50) * (1 - val)), 0.5, 0);
    AnimHelper.animateInOut(baseFactor + delta, 0.0, anim.duration, 1.0, 0, 1, (val) => (this.animatedNumber.alpha = val), 0.5, 0);
    AnimHelper.animateInOut(baseFactor + delta, 0.0, anim.duration, 1.0, 0, 1, (val) => (this.animatedNumber.x = _s(this.oddsAlwaysOn ? 203 : 280) - _s(50) * (1 - val)), 0.5, 0);
  }
}

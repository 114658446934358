import { bottomBarSettings } from "../../settings/OddsAlwaysOnSettings";
import { settings } from "./../../Logic/Logic";
import { Group } from "../../Graphics/Group";
import { BottomBarItemDog } from "./BottomBarItemDog";
import { _s, Logic } from "../../Logic/Logic";
import { IDriver, IColors, IGameInfo } from "../../Logic/LogicDefinitions";
import { DogHelper } from "./DogHelper";
import * as PIXI from "pixi.js";

export class BottomBarDog extends Group {
  private items: BottomBarItemDog[] = [];

  public constructor(gameInfo: IGameInfo, helper: DogHelper, oddsAlwaysOn = false) {
    super();
    const gameType = gameInfo.gameType;
    this.showDebug(settings.debug, 1, "BottomBarDog");

    const racerCount = Logic.getRacerCount(gameType);
    const left = gameType === "dog8" ? 22 : 42;
    const topY = 672;
    let itemSize = gameType === "dog8" ? 153.66 : 205.5;
    if (gameType === "horse" || gameType === "sulky") itemSize = 171.66;

    if (oddsAlwaysOn) itemSize = bottomBarSettings[gameType as keyof typeof bottomBarSettings].itemSize;

    const height = 22;

    for (let i = 0; i < racerCount; i++) {
      const bbi = new BottomBarItemDog(i, gameInfo, helper, oddsAlwaysOn);
      bbi.position.x = _s(left + i * itemSize);
      bbi.position.y = _s(topY);
      bbi.width = _s(itemSize);
      bbi.height = _s(height);
      this.items.push(bbi);
      this.add(bbi);
    }
  }

  public fill(drivers: IDriver[], colors: IColors, withBonus: boolean, language: string) {
    for (let i = 0; i < this.items.length; i++) this.items[i].fill(drivers[i], colors, withBonus, language);
  }

  public update(dt: number) {
    super.update(dt);
  }
}

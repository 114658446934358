/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Chalk } from 'chalk';

const ctx = new Chalk({ level: 1 });

export class Logger {
  private static logGuards = false;
  private static enableExceptions = true;

  public static enableGuards(flag: boolean, exceptions: boolean): void {
    Logger.logGuards = flag;
    Logger.enableExceptions = exceptions;
  }

  private static log(message?: any, ...optionalParams: any[]) {
    console.log(message, ...optionalParams);
  }

  public static debug(message?: any, ...optionalParams: any[]): void {
    this.log(ctx.white(Logger.getTimeString() + " - DEBUG: ") + message, ...optionalParams);
  }

  private static logGuard(message?: any, ...optionalParams: any[]) {
    this.log(ctx.greenBright(Logger.getTimeString() + " - GUARD: ") + message, ...optionalParams);
  }

  public static info(message?: any, ...optionalParams: any[]): void {
    this.log(ctx.gray(Logger.getTimeString() + " - INFO: ") + message, ...optionalParams);
  }

  public static warn(message?: any, ...optionalParams: any[]): void {
    this.log(ctx.magentaBright(Logger.getTimeString() + " - WARN: ") + message, ...optionalParams);
  }

  public static error(message?: any, ...optionalParams: any[]): void {
    this.log(ctx.red(Logger.getTimeString() + " - ERROR: ") + message, ...optionalParams);
  }

  private static getTimeString(): string {
    const currentTime = new Date().toISOString();
    return currentTime;
  }

  public static async guardAsync<T>(name: string, p: () => Promise<T>): Promise<T | undefined> {
    if (!this.logGuards) return await p();
    const startStack = new Error().stack;
    await this.catchedAsync(
      async () => {
        this.logGuard("Before " + name);
        const ret = await p();
        this.logGuard("After " + name);
        return ret;
      },
      (e) => {
        Logger.error("GUARD Error: " + name, e, startStack);
      }
    );
  }

  public static guard(name: string, callback: () => any): any {
    if (!this.logGuards) return callback();
    const startStack = new Error().stack;
    this.catched(
      () => {
        this.logGuard("Before " + name);
        const ret = callback();
        this.logGuard("After " + name);
        return ret;
      },
      (e) => {
        Logger.error("GUARD Error: " + name, e, startStack);
      }
    );
  }

  public static catched<T>(fn: () => T, catched: string | ((e: Error) => void)): T | undefined {
    const withExceptions = false;
    if (withExceptions) {
      try {
        return fn();
      } catch (e: any) {
        if (typeof catched === "string") Logger.error(catched, e);
        else catched(e);
      }
    } else {
      // we want the debugger to catch the exception...
      return fn();
    }
  }

  public static async catchedAsync<T>(fn: () => Promise<T>, catched: string | ((e: Error) => void)) {
    const withExceptions = false;
    if (withExceptions) {
      try {
        return await fn();
      } catch (e: any) {
        if (typeof catched === "string") Logger.error(catched, e);
        else catched(e);
      }
    } else {
      // we want the debugger to catch the exception...
      return await fn();
    }
  }
}

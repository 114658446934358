export const mainElementPositionSizes = {
  dog6: {
    oddsScreen: {
      pivot: {
        x: 1126,
        y: 13
      },
      x: 168,
      y: 102,
      width: 987,
      height: 531
    },
    racingHistory: {
      x: 65,
      y: 120,
      width: 286,
      height: 380
    },
    bonusHistory: {
      x: 65,
      y: 170,
      width: 370,
      height: 315
    },
    animatedBonusBar: {
      x: 77,
      y: 119,
      width: 360,
      height: 35
    },
    sendPlan: {
      x: 230,
      y: 210,
      width: 815,
      heigth: 320
    }
  },
  dog8: {
    oddsScreen: {
      pivot: {
        x: 1158,
        y: 82
      },
      x: 120,
      y: 84,
      width: 1030,
      height: 540
    },
    racingHistory: {
      x: 85,
      y: 120,
      width: 286,
      height: 380
    },
    bonusHistory: {
      x: 65,
      y: 169,
      width: 370,
      height: 315
    },
    animatedBonusBar: {
      x: 77,
      y: 119,
      width: 360,
      height: 35
    },
    sendPlan: {
      x: 80,
      y: 175,
      width: 300,
      heigth: 320
    }
  },
  horse: {
    oddsScreen: {
      pivot: {
        x: 1126,
        y: 13
      },
      x: 1294,
      y: 115,
      width: 987,
      height: 531
    },
    racingHistory: {
      x: 65,
      y: 120,
      width: 286,
      height: 380
    },
    bonusHistory: {
      x: 65,
      y: 170,
      width: 370,
      height: 315
    },
    animatedBonusBar: {
      x: 77,
      y: 119,
      width: 360,
      height: 35
    },
    sendPlan: {
      x: 230,
      y: 210,
      width: 815,
      heigth: 320
    }
  }
};

// export const mainElementPositionSizesIt = {
//   get
// }

export const oddScreenSettings = {
  dog6: {
    rowStartX: 185,
    rowStartY: 118,
    rowHeight: 72.5,
    columnWidth: 144,
    first: {
      x: 8,
      y: 457
    },
    second: {
      x: 912,
      y: 8
    },
    itHeader: {
      x: 167,
      y: -3
    },
    oddsScreenScaleTo: 0.73,
    oddsScreenPosXTo: 472,
    oddsScreenPosYTo: 106
  },
  dog8: {
    rowStartX: 149,
    rowStartY: 100,
    rowHeight: 58,
    columnWidth: 116,
    first: {
      x: 6.8,
      y: 483
    },
    second: {
      x: 972,
      y: 9.5
    },
    itHeader: {
      x: 80.5,
      y: -3.5
    },
    oddsScreenScaleTo: 0.692,
    oddsScreenPosXTo: 476.5,
    oddsScreenPosYTo: 110
  }
};

export const raceingHistorySettings = {
  dog6: {
    titleText: {
      x: 103,
      y: 2
    },
    rowStartY: 30,
    rowOffset: 70.3,
    row: {
      raceNumber: {
        yOffset: 14,
        x: 46
      },
      bonusAnnotation: {
        x: 15,
        y: 18
      },
      raceText: {
        x: 50
      },
      firstNumbers: {
        x: 98
      },
      secondNumber: {
        x: 60
      },
      quotes: {
        x: 225
      },
      allLines: {
        height: 26.5,
        width: 4
      },
      firstLine1: {
        x: 112,
        yOffset: 3.5
      },
      firstLine2: {
        x: 172,
        yOffset: 3.5
      },
      secondLine: {
        yOffset: 3.5
      }
    }
  },
  dog8: {
    titleText: {
      x: 82,
      y: 5
    },
    rowStartY: 27,
    rowOffset: 69.5,
    row: {
      raceNumber: {
        yOffset: 14,
        x: 34
      },
      bonusAnnotation: {
        x: 2,
        y: 15
      },
      raceText: {
        x: 38
      },
      firstNumbers: {
        x: 85
      },
      secondNumber: {
        x: 59
      },
      quotes: {
        x: 230
      },
      allLines: {
        height: 26,
        width: 4
      },
      firstLine1: {
        x: 98,
        yOffset: 5
      },
      firstLine2: {
        x: 158,
        yOffset: 5
      },
      secondLine: {
        yOffset: 5
      }
    }
  }
};

export const bottomBarSettings = {
  dog6: {
    itemSize: 204.5,
    lineHeigth: 106,
    xOffsetPerField: 20 / 4,
    key: {
      xOffset: 24,
      yOffset: 12
    },
    mask: {
      width: 183,
      heigth: 106
    },
    infoGroupWithMask: {
      x: 18.5,
      y: -106
    },
    finalKeyValXPos: {
      key: 29,
      value: 138
    }
  },
  dog8: {
    itemSize: 153,
    lineHeigth: 89,
    xOffsetPerField: 14 / 4,
    key: {
      xOffset: 26,
      yOffset: 28
    },
    mask: {
      width: 154,
      heigth: 106
    },
    infoGroupWithMask: {
      x: 18,
      y: -106
    },
    finalKeyValXPos: {
      key: 22.5,
      value: 114
    }
  }
};

export const sendPlanSettings = {
  dog6: {
    lineHeight: 41
  },
  dog8: {
    lineHeight: 34.4
  }
};

export const raceBarSettings = {
  dog6: {
    raceIdText: {
      x: 68,
      y: 9
    },
    raceText: {
      x: -27,
      y: 22
    },
    lapText: {
      x: 21,
      y: 21.5
    },
    timeText: {
      x: 130,
      y: 21
    }
  },
  dog8: {
    raceIdText: {
      x: 92,
      y: 69.5
    },
    raceText: {
      x: -29,
      y: 21
    },
    lapText: {
      x: 20,
      y: 21
    },
    timeText: {
      x: 139,
      y: 21
    }
  }
};

export const BonusHistorySettings = {
  dog6: {
    rowOffset: 65,
    title: {
      x: 55,
      y: 27
    }
  },
  dog8: {
    rowOffset: 63.8,
    title: {
      x: 74,
      y: 27
    }
  }
};

export const oddsAlwaysOnStyles = {
  dog6: {
    oddsScreen: {
      firstSecond: {
        fontSize: 14,
        letterSpacing: 4.2
      },
      odds: {
        fontSize: 34,
        fontFamily: "DIN-Bold"
      },
      oddsHeaderVincente: {
        fontSize: 16
      },
      oddsHeaderAccopiata: {
        fontSize: 16
      }
    },
    bottomBar: {
      fontSize: 14
    },
    sendPlan: {
      sendPlanNumber: {
        fontSize: 18
      }
    },
    raceBar: {
      raceId: {
        fontFamily: "DIN-BoldItalic",
        fontSize: 10.6
      },
      lapText: {
        fontFamily: "DIN-Regular",
        letterSpacing: -0.9
      },
      countDownText: {
        fontFamily: "DIN-RegularItalic",
        letterSpacing: 7
      }
    },
    bonusHistory: {
      title: { fontSize: 21 }
    }
  },
  dog8: {
    oddsScreen: {
      firstSecond: {
        fontSize: 11,
        letterSpacing: 3.8
      },
      odds: {
        fontSize: 28,
        fontFamily: "DIN-Bold"
      },
      oddsHeaderVincente: {
        fontSize: 28
      },
      oddsHeaderAccopiata: {
        fontSize: 16
      }
    },
    bottomBar: {
      fontSize: 12
    },
    sendPlan: {
      sendPlanNumber: {
        fontSize: 16
      }
    },
    raceBar: {
      raceId: {
        fontFamily: "DIN-BoldItalic",
        fontSize: 10.6
      },
      lapText: {
        fontFamily: "DIN-Regular",
        letterSpacing: -0.9
      },
      countDownText: {
        fontFamily: "DIN-RegularItalic",
        letterSpacing: 8
      }
    },
    bonusHistory: {
      title: {
        fontSize: 21
      }
    }
  }
};

export const oddsAlwaysOnTimings = {
  dog6: {
    oddsScreen: {
      default: [
        { startTime: 1.1, duration: 19.4, initAnimation: true },
        {
          startTime: 20.6,
          duration: 180.053333 - 23.6,
          subAnimations: [
            { startTime: 20.53, duration: 43.911, fadeInFactor: 1, fadeOutFactor: 0.85, smoothness: { in: 1.46199, out: 1.87 } },
            { startTime: 83, duration: 43.437, fadeInFactor: 0.58, fadeOutFactor: 0.85, smoothness: { in: 2.02, out: 1.86 } },
            { startTime: 146.58, duration: 16.85, fadeInFactor: 1, fadeOutFactor: 0.85, smoothness: { in: 1.72, out: 1.87 } }
          ]
        }
      ],
      it: [
        { startTime: 0, duration: 19.4, initAnimation: true },
        {
          startTime: 0,
          duration: 180.053333 - 23.6,
          subAnimations: [
            { startTime: 0, duration: 10, fadeInFactor: 1, fadeOutFactor: 0.82, smoothness: { in: 1.499, out: 1.87 } },
            { startTime: 83, duration: 43.48, fadeInFactor: 0.58, fadeOutFactor: 0.85, smoothness: { in: 2.02, out: 1.87 } },
            { startTime: 146.6, duration: 16.9, fadeInFactor: 1, fadeOutFactor: 0.88, smoothness: { in: 1.82, out: 1.87 } }
          ]
        }
      ]
    },
    bottomBar: {
      factor: 0.15,
      anims: [
        { startTime: 22, duration: 41, infoStartTime: 0.3, infoTime: 0.2, fadeInTime: 0.6, fadeOutStart: 40.13, fadeOutTime: 0.3, fadeTimePerLine: undefined, atOnce: true }, // general bar fade in/out2
        { startTime: 84.4, duration: 41.1, infoStartTime: 0.3, infoTime: 0.2, fadeInTime: 0.6, fadeOutStart: 40, fadeOutTime: 0.3, fadeTimePerLine: undefined, atOnce: true },
        { startTime: 148, duration: 13.7, infoStartTime: 0.3, infoTime: 0.2, fadeInTime: 0.6, fadeOutStart: 13.45, fadeOutTime: 0.3, fadeTimePerLine: undefined, atOnce: true }
      ]
    },
    itHeader: [
      [
        { startTime: 12.8, duration: 3.25 },
        { startTime: 90.7, duration: 4.3 },
        { startTime: 140.8, duration: 5.7 }
      ],
      [
        { startTime: 16.55, duration: 3.25 },
        { startTime: 95.57, duration: 2.6 },
        { startTime: 147.25, duration: 3.4 }
      ]
    ]
  },
  dog8: {
    oddsScreen: {
      default: [
        { startTime: 0.7, duration: 19.4, initAnimation: true },
        {
          startTime: 20.6,
          duration: 180.053333 - 23.6,
          subAnimations: [
            { startTime: 20.525, duration: 43.87, fadeInFactor: 1, fadeOutFactor: 0.85, smoothness: { in: 1.5324, out: 1.7 } },
            { startTime: 82.97, duration: 43.58, fadeInFactor: 0.58, fadeOutFactor: 1, smoothness: { in: 2.02, out: 1.69 } },
            { startTime: 146.59, duration: 16.96, fadeInFactor: 1, fadeOutFactor: 1, smoothness: { in: 1.82, out: 1.69 } }
          ]
        }
      ],
      it: [
        { startTime: 0.7, duration: 19.4, initAnimation: true, startSm: true },
        {
          startTime: 3,
          duration: 180.053333 - 6,

          subAnimations: [
            { startTime: 0, duration: 10.75, fadeInFactor: 1, fadeOutFactor: 0.91, smoothness: { in: 1.499, out: 1.93 } },
            { startTime: 20.62, duration: 43.83, fadeInFactor: 0.92, fadeOutFactor: 0.88, smoothness: { in: 1.92, out: 1.87 } },
            { startTime: 82.98, duration: 43.5, fadeInFactor: 0.58, fadeOutFactor: 0.85, smoothness: { in: 2.02, out: 1.87 } },
            { startTime: 146.6, duration: 16.9, fadeInFactor: 1, fadeOutFactor: 0.88, smoothness: { in: 1.82, out: 1.87 } }
          ]
        }
      ]
    },
    bottomBar: {
      factor: 0.0,
      anims: [
        { startTime: 22.5, duration: 40, infoStartTime: 0.3, infoTime: 0.2, fadeInTime: 0.6, fadeOutStart: 39.9, fadeOutTime: 0.3, fadeTimePerLine: 0.17, atOnce: true }, // general bar fade in/out2
        { startTime: 84.85, duration: 40.6, infoStartTime: 0.3, infoTime: 0.2, fadeInTime: 0.6, fadeOutStart: 39.35, fadeOutTime: 0.3, fadeTimePerLine: 0.17, atOnce: true },
        { startTime: 148.5, duration: 13.88, infoStartTime: 0.3, infoTime: 0.2, fadeInTime: 0.6, fadeOutStart: 13.05, fadeOutTime: 0.3, fadeTimePerLine: 0.17, atOnce: true }
      ]
    },
    itHeader: [
      [
        { startTime: 12.8, duration: 3.25 },
        { startTime: 66.3, duration: 3.25 },
        { startTime: 128.6, duration: 3.35 },
        { startTime: 165.6, duration: 3.35 }
      ],
      [
        { startTime: 16.55, duration: 3.25 },
        { startTime: 70.05, duration: 3.25 },
        { startTime: 132.4, duration: 3.25 },
        { startTime: 169.5, duration: 3.25 }
      ]
    ]
  }
};

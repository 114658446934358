import * as React from "react";
import { asComponent } from "./ReactHelper";
import { makeStyles } from "@material-ui/styles";
import { Settings } from "../common/Settings";

const useStyles = makeStyles({
  updateOverlay: {
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "#EEEEEE",
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    justifyContent: "center"
  },
  text: {
    margin: "5px"
  },
  text2: {
    margin: "5px",
    fontSize: "85%"
  }
});

export const StartOverlay = asComponent("StartOverlay", (props: { ready: boolean; onStart: () => void }) => {
  const styles = useStyles();
  return (
    <div className={styles.updateOverlay} onClick={props.ready ? props.onStart : () => console.log("Not ready yet")}>
      <div className={styles.text}>Start Overlay</div>
      <div className={styles.text2}>{props.ready ? "Touch screen to start" : "Please wait..."}</div>
      <br />
      <div className={styles.text2}>Version: {Settings.versionNumber}</div>
    </div>
  );
});

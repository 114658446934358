import { Util } from "../../common/Util";
import { DriverPresentationDog } from "./DriverPresentationDog";
import { GameLength, GameType } from "../../common/Definitions";
import { DogHelper } from "./DogHelper";
import { AnimHelper } from "./../common/Anim";
import { IAnimInterval } from "./../../Logic/LogicDefinitions";
import * as PIXI from "pixi.js";
import { Group } from "../../Graphics/Group";
import { _s, Logic, settings } from "../../Logic/Logic";
import { IDriver } from "../../Logic/LogicDefinitions";

type AnimInterval = IAnimInterval & { addLast: number; addSecondToLast?: number; pause: number };
export class DriverInfoBarDog extends Group {
  public driverBarInfo: PIXI.Text;
  private gameType: "dog6" | "dog8" | "dog63" | "horse" | "sulky";
  private gameLength: 120 | 180 | 240 | 300;
  private helper: DogHelper;
  public anims: AnimInterval[] | AnimInterval = [{ startTime: 0.0, duration: 0.0, addLast: 0.0, pause: 0.0 }];
  public drivers: IDriver[] = [];
  private currentDriverIndex: number = -1;
  private racerCount: number;
  private oddsAlwaysOn;

  public constructor(gameType: GameType, gameLength: GameLength, helper: DogHelper, oddsAlwaysOn = false) {
    super();
    this.gameType = gameType as "dog6" | "dog8" | "dog63" | "horse" | "sulky";
    this.gameLength = gameLength as 120 | 180 | 240 | 300;
    this.helper = helper;
    this.showDebug(settings.debug, undefined, "DriverInfoBar");
    this.racerCount = Logic.getRacerCount(this.gameType);
    this.oddsAlwaysOn = oddsAlwaysOn;

    const defaultStyle = new PIXI.TextStyle({
      fontFamily: "DIN-UltraLightItalic",
      fontSize: _s(12),
      fill: "#EEE",
      letterSpacing: _s(1.6),
      align: "center",
      fontStyle: "italic"
    });

    this.driverBarInfo = Logic.createPixiText(defaultStyle);
    this.driverBarInfo.anchor.set(0.5, 0);
    this.add(this.driverBarInfo);
  }

  public fill(drivers: IDriver[], withBonus: boolean): void {
    this.drivers = drivers;
    this.anims = DriverPresentationDog.createAnim(this.gameType, this.gameLength, withBonus, this.oddsAlwaysOn).bar;
  }

  public onLayout(): void {
    this.driverBarInfo.x = this.width * 0.5;
    this.driverBarInfo.y = _s(1);
    this.driverBarInfo.alpha = 1;
  }

  public updateTexts(currentIndex: number): boolean {
    const drivers = this.drivers;
    if (!drivers || !drivers[currentIndex]) {
      return false;
    }
    const driver = drivers[currentIndex];
    this.driverBarInfo.text = driver.driverBarText;
    return true;
  }

  public update(): void {
    const currentTime = Logic.getVideoTime();
    const currentBarAnim = !Array.isArray(this.anims) ? this.anims : Logic.getAnim(currentTime, this.anims, this);
    if (!currentBarAnim) return;

    if (Array.isArray(currentBarAnim)) {
      if (currentTime > currentBarAnim.startTime + currentBarAnim.duration) {
        this.visible = false;
        return;
      }
    }
    this.visible = true;

    let barDriverIndex = Util.clamp(Math.floor((currentTime - currentBarAnim.startTime) / (currentBarAnim.duration + currentBarAnim.pause)), 0, this.racerCount - 1);
    let barAnim = {
      startTime: currentBarAnim.startTime + barDriverIndex * (currentBarAnim.duration + currentBarAnim.pause),
      duration: currentBarAnim.duration + (barDriverIndex >= this.racerCount - 1 ? currentBarAnim.addLast : 0)
    };

    if (Array.isArray(this.anims)) {
      barDriverIndex = this.anims.indexOf(currentBarAnim);
      barAnim = currentBarAnim;
    }

    if (barDriverIndex !== undefined && barDriverIndex !== this.currentDriverIndex) {
      if (this.updateTexts(barDriverIndex)) this.currentDriverIndex = barDriverIndex;
    }
    const barAnimRelativeStartTime = currentTime - barAnim?.startTime;
    const dur2 = currentBarAnim.duration - 1.0;

    if (barAnimRelativeStartTime < dur2) {
      this.driverBarInfo.alpha = (barAnimRelativeStartTime - 0.33) * 3;
    } else {
      this.driverBarInfo.alpha = (1.0 - (barAnimRelativeStartTime - dur2)) * 3;
    }
  }
}

import * as PIXI from "pixi.js";
import { Group } from "../../Graphics/Group";
import { _s, settings, _t } from "../../Logic/Logic";
import { DynamicGeometry } from "../../Graphics/DynamicMesh";
import { Util } from "../../common/Util";

import { Logic } from "../../Logic/Logic";
import { IDriver, IAnimInterval, DriverPattern } from "../../Logic/LogicDefinitions";
import { DrawHelper } from "../common/DrawHelper";
import { GameType, GameLength } from "../../common/Definitions";
import { DogHelper } from "./DogHelper";

type AnimInterval = IAnimInterval & { addLast: number; addSecondToLast?: number; pause: number };

export class DriverPresentationDog extends Group {
  private driverTitle: PIXI.Text;
  private driverName: PIXI.Text;
  private driverNumberMain!: PIXI.Text;
  private oddsNumberMain!: PIXI.Text;
  private driverNumberCol1: PIXI.Text[] = [];
  private driverNumberCol2: PIXI.Text[] = [];
  private driverNumberfirstLine1: PIXI.Sprite[] = [];
  private driverNumberfirstLine2: PIXI.Sprite[] = [];
  private driverNumberFirstText: PIXI.Text[] = [];
  private driverNumberSecondText: PIXI.Text[] = [];
  private driverOdds: PIXI.Text[] = [];
  private currentDriverIndex: number = -1;
  private drivers: IDriver[] = [];
  private odds: number[] = [];

  private offset: number = 64;
  private offsetHeight: number = 360;
  private originY: number = 0;
  private gameType: GameType;
  private gameLength: GameLength;
  private racerCount: number;
  private anim: AnimInterval | AnimInterval[] = { startTime: 0.0, duration: 0.0, addLast: 0, pause: 0 };
  private helper: DogHelper;
  public oddsAlwaysOn;
  public constructor(gameType: GameType, gameLength: GameLength, helper: DogHelper, oddsAlwaysOn = false) {
    super();
    this.helper = helper;
    this.gameType = gameType;
    this.gameLength = gameLength;
    this.racerCount = Logic.getRacerCount(gameType);
    this.oddsAlwaysOn = oddsAlwaysOn;
    const dg = new DynamicGeometry("Pos2Color", 100, 150);
    this.add(dg);
    this.showDebug(settings.debug, 1, "DriverPresentationDog");

    this.driverTitle = Logic.createPixiText(this.helper.getDriverPresentationTitleStyle());
    this.driverTitle.anchor.set(0.5, 0.5);
    this.add(this.driverTitle);

    this.driverName = Logic.createPixiText(this.helper.getDriverPresentationNameStyle());
    this.driverName.anchor.y = 0.5;
    this.add(this.driverName);

    this.driverNumberMain = Logic.createPixiText(this.helper.getDriverPresentationNumberMainStyle());
    this.driverNumberMain.anchor.set(0.5);
    this.driverNumberMain.roundPixels = true;
    // this.add(this.driverNumberMain);

    const styleOdds = this.helper.getDriverPresentationQuoteStyle();
    this.oddsNumberMain = Logic.createPixiText(styleOdds);
    this.oddsNumberMain.anchor.set(1.0, 0.5);
    this.oddsNumberMain.roundPixels = true;
    // this.add(this.oddsNumberMain); // MS not needed here?

    const styleBoldSmall = this.helper.getDriverPresentationSmallBoldStyle();
    for (let i = 0; i < this.racerCount; i++) {
      this.driverNumberCol1.push(Logic.createPixiText(styleBoldSmall));
      this.driverNumberCol1[i].anchor.set(0.5);
      this.driverNumberCol1[i].roundPixels = true;
      this.add(this.driverNumberCol1[i]);

      const spriteLine1 = new PIXI.Sprite();
      const spriteLine2 = new PIXI.Sprite();
      this.driverNumberfirstLine1.push(spriteLine1);
      this.driverNumberfirstLine2.push(spriteLine2);
      if (gameType !== "horse" && gameType !== "sulky") {
        this.add(spriteLine1);
        this.add(spriteLine2);
      }

      const firstText = Logic.createPixiText(this.helper.getFirstTextStyle());
      this.driverNumberFirstText.push(firstText);
      if (gameType === "horse" || gameType === "sulky") this.add(firstText);

      const secondText = Logic.createPixiText(this.helper.getFirstTextStyle());
      this.driverNumberSecondText.push(secondText);
      if (gameType === "horse" || gameType === "sulky") this.add(secondText);

      this.driverNumberCol2.push(Logic.createPixiText(styleBoldSmall));
      this.driverNumberCol2[i].anchor.set(0.5);
      this.driverNumberCol2[i].roundPixels = true;
      this.add(this.driverNumberCol2[i]);
      this.driverOdds.push(Logic.createPixiText(styleOdds));
      this.driverOdds[i].anchor.set(1.0, 0.5);
      this.driverOdds[i].roundPixels = true;
      this.add(this.driverOdds[i]);
    }
  }

  //
  public static createAnim(gameType: GameType, gameLength: GameLength, withBonus: boolean, oddsAlwaysOn = false): { top: AnimInterval | AnimInterval[]; bar: AnimInterval | AnimInterval[] } {
    if (oddsAlwaysOn) {
      return {
        top: { startTime: 0, duration: 0, pause: 0, addLast: 0.0 },
        bar: { startTime: 0, duration: 0, pause: 0, addLast: 0.0 }
      };
    }
    if (gameType === "horse" || gameType === "sulky") {
      return {
        top: [
          { startTime: 70.8, duration: 4.0, pause: 0.0, addSecondToLast: 0.2, addLast: 0.4 },
          { startTime: 74.8, duration: 4.0, pause: 0.0, addSecondToLast: 0.2, addLast: 0.4 },
          { startTime: 78.8, duration: 4.0, pause: 0.0, addSecondToLast: 0.2, addLast: 0.4 },
          { startTime: 82.8, duration: 4.0, pause: 0.0, addSecondToLast: 0.2, addLast: 0.4 },
          { startTime: 86.8, duration: 4.0, pause: 0.0, addSecondToLast: 0.2, addLast: 0.4 },
          { startTime: 90.8, duration: 4.5, pause: 0.0, addSecondToLast: 0.2, addLast: 0.4 },
          { startTime: 95.5, duration: 4.3, pause: 0.0, addSecondToLast: 0.2, addLast: 0.4 }
        ],
        bar: [
          { startTime: 70.8, duration: 4.3, pause: 0.0, addLast: 0.0 },
          { startTime: 75.15, duration: 3.7, pause: 0.0, addLast: 0.0 },
          { startTime: 79.25, duration: 3.5, pause: 0.0, addLast: 0.0 },
          { startTime: 83.2, duration: 3.6, pause: 0.0, addLast: 0.0 },
          { startTime: 86.95, duration: 3.85, pause: 0.0, addLast: 0.0 },
          { startTime: 91.2, duration: 3.6, pause: 0.0, addLast: 0.0 },
          withBonus ? { startTime: 95.5, duration: 3.3 /*3.1*/, pause: 0.0, addLast: 0.0 } : { startTime: 95.25, duration: 3.65, pause: 0.0, addLast: 0.0 }
        ]
      };
    }
    if (gameType === "dog6") {
      switch (gameLength) {
        case 120:
          return {
            top: { startTime: 0, duration: 0, pause: 0, addLast: 0.0 },
            bar: { startTime: 0, duration: 0, pause: 0, addLast: 0.0 }
          };
        case 180:
          return {
            top: withBonus ? { startTime: 60.9, duration: 2.95, pause: 0.15, addLast: 0.4 } : { startTime: 70.7, duration: 3.04, pause: 0.1, addLast: 0.3 },
            bar: withBonus ? { startTime: 60.9, duration: 2.95, pause: 0.15, addLast: 0.0 } : { startTime: 70.7, duration: 3.04, pause: 0.1, addLast: 0.0 }
          };
        case 240:
          return {
            top: { startTime: 71.1, duration: 2.85, pause: 0.2, addLast: 0.4 },
            bar: { startTime: 70.6, duration: 3.1, pause: 0.05, addLast: -0.05 }
          };
        case 300:
          return {
            top: withBonus ? { startTime: 70.75, duration: 2.95, pause: 0.2, addLast: 0.35 } : { startTime: 80.8, duration: 2.85 /*3.1*/, pause: 0.3, addLast: 0.4 },
            bar: withBonus ? { startTime: 70.75, duration: 2.95, pause: 0.2, addLast: 0.0 } : { startTime: 80.8, duration: 2.85 /*3.1*/, pause: 0.3, addLast: 0.0 }
          };
        default:
          return {
            top: { startTime: 70.5, duration: 4.26, pause: 0.3, addLast: 0.4 },
            bar: { startTime: 70.5, duration: 4.26, pause: 0.3, addLast: 0.0 }
          };
      }
    }
    // dog8
    switch (gameLength) {
      case 120:
        return {
          top: { startTime: 0, duration: 0, pause: 0, addLast: 0.0 },
          bar: { startTime: 0, duration: 0, pause: 0, addLast: 0.0 }
        };
      case 180:
        return {
          top: withBonus ? { startTime: 60.5, duration: 2.32, pause: 0.05, addLast: 0.3 } : { startTime: 70.5, duration: 2.32, pause: 0.05, addLast: 0.3 },
          bar: withBonus ? { startTime: 60.2, duration: 2.48, pause: 0, addLast: 0.0 } : { startTime: 70.2, duration: 2.48, pause: 0, addLast: 0.0 }
        };
      case 240:
        return {
          top: { startTime: 70.7, duration: 3.4, pause: 0.2, addLast: 0.3 },
          bar: { startTime: 70.4, duration: 3.45, pause: 0.2, addLast: 0.0 }
        };
      case 300:
        return {
          top: withBonus ? { startTime: 70.5, duration: 3.6, pause: 0, addLast: 0.4 } : { startTime: 80.5, duration: 3.6, pause: 0, addLast: 0.4 },
          bar: withBonus ? { startTime: 70.5, duration: 3.7, pause: 0, addLast: 0.0 } : { startTime: 80.5, duration: 3.7, pause: 0, addLast: 0.0 }
        };
      default:
        return {
          top: { startTime: 76.92, duration: 6.52, pause: 0.3, addLast: 0.4 },
          bar: { startTime: 76.92, duration: 6.52, pause: 0.3, addLast: 0.0 }
        };
    }
  }

  public fill(drivers: IDriver[], odds: number[], withBonus: boolean) {
    const anims = DriverPresentationDog.createAnim(this.gameType, this.gameLength, withBonus, this.oddsAlwaysOn);
    this.anim = anims.top;

    this.drivers = drivers;
    this.odds = odds;
  }

  private getPattern(pattern: DriverPattern | undefined, color1: number, color2: number | undefined): PIXI.Texture {
    if (pattern === DriverPattern.BLACK_WHITE_6)
      // the black_white pattern looks different in the tilted driverpresentation...
      return DrawHelper.getCachedPattern(10, 32, 5, color2 === undefined ? 0xff000000 : color2, color1, DriverPattern.BLACK_WHITE_6_b);
    return DrawHelper.getCachedPattern(10, 32, 5, color1, color2, pattern);
  }

  public updateDriverTexts(index: number) {
    const odds = this.odds;
    const drivers = this.drivers;
    if (!drivers || !drivers[index]) {
      return false;
    }

    this.driverTitle.text = this.gameType === "horse" || this.gameType === "sulky" ? _t("runner") : _t("dogRunner");
    Logic.autoSize(this.driverTitle, _s(90));

    const driver = drivers[index];
    this.driverName.text = driver.firstName.toUpperCase();
    Logic.autoSize(this.driverName, _s(140));
    // this.driverName.updateTexts([driver.firstName, driver.lastName]);

    const driverNumber = "" + (index + 1);
    this.driverNumberMain.text = driverNumber;
    this.driverNumberMain.tint = driver.color;

    const oddsMainDriver = Logic.getOddsForDriver(odds, index, index, drivers.length);
    this.oddsNumberMain.text = Logic.implementation.formatOdds(oddsMainDriver);

    // set firs one separately{
    {
      this.driverNumberCol1[0].text = driverNumber;
      this.driverNumberCol2[0].text = "";

      const texture = this.getPattern(driver.driverPattern, driver.color, driver.color2);
      this.driverNumberfirstLine1[0].texture = texture;
      this.driverNumberfirstLine1[0].alpha = 0;

      const oddsCurrentDriver = Logic.getOddsForDriver(odds, index, index, drivers.length);
      this.driverOdds[0].text = Logic.implementation.formatOdds(oddsCurrentDriver);

      this.driverNumberFirstText[0].text = _t("numberSign");
      this.driverNumberSecondText[1].text = "";
    }

    for (let i = 1; i < this.racerCount; i++) {
      this.driverNumberCol1[i].text = "" + driverNumber;
      const otherDriverIndex = i - 1 + (index <= i - 1 ? 1 : 0);
      this.driverNumberCol2[i].text = "" + (otherDriverIndex + 1);
      // this.driverNumberCol2[i].tint = drivers[otherDriverIndex].color;

      this.driverNumberFirstText[i].text = _t("numberSign");
      this.driverNumberSecondText[i].text = _t("numberSignTwo");

      this.driverNumberfirstLine1[i].texture = this.getPattern(driver.driverPattern, driver.color, driver.color2);
      this.driverNumberfirstLine2[i].texture = this.getPattern(drivers[otherDriverIndex].driverPattern, drivers[otherDriverIndex].color, drivers[otherDriverIndex].color2);

      const oddsCurrentDriver = Logic.getOddsForDriver(odds, index, otherDriverIndex, drivers.length);
      this.driverOdds[i].text = Logic.implementation.formatOdds(oddsCurrentDriver);
    }

    return true;
  }

  private static shiftXByOffset(originX: number, originY: number, targetY: number, offset: number, offsetHeight: number) {
    return originX + (originY - targetY) * (offset / offsetHeight);
  }

  private shiftXByOffset(originX: number, targetY: number) {
    return DriverPresentationDog.shiftXByOffset(originX, this.originY, targetY, this.offset, this.offsetHeight);
  }
  public onLayout() {
    this.originY = this.height / _s(1);

    const rowShiftX = this.gameType === "dog8" ? 0 : 9;

    if (this.gameType === "horse" || this.gameType === "sulky") {
      this.driverTitle.position.y = _s(-22);
      this.driverTitle.position.x = _s(136);
    } else {
      this.driverTitle.position.y = _s(this.gameType === "dog8" ? -77 : -20);
      this.driverTitle.position.x = _s(138);
    }

    this.driverName.position.x = _s(this.shiftXByOffset(42, 2));
    this.driverName.position.y = _s(this.gameType === "dog8" ? 13 : 69);

    this.driverNumberMain.position.x = _s(119);
    this.driverNumberMain.position.y = _s(64);

    this.oddsNumberMain.position.x = _s(315);
    this.oddsNumberMain.position.y = _s(65);

    const rowHeight = 41.7;
    const rowStartY = this.gameType === "dog8" ? 51 : 108;

    for (let i = 0; i < this.racerCount; i++) {
      this.driverNumberCol1[i].y = _s(rowStartY + i * rowHeight);
      this.driverNumberCol1[i].x = _s(rowShiftX + this.shiftXByOffset(32, rowStartY + i * rowHeight));
      this.driverNumberCol2[i].y = _s(rowStartY + i * rowHeight);
      this.driverNumberCol2[i].x = _s(rowShiftX + this.shiftXByOffset(80, rowStartY + i * rowHeight));
      if (this.gameType === "horse" || this.gameType === "sulky") {
        this.driverOdds[i].y = _s(rowStartY + i * rowHeight + 1 - 1);
        this.driverOdds[i].x = _s(rowShiftX + this.shiftXByOffset(184, rowStartY + i * rowHeight) - 3);
      } else {
        this.driverOdds[i].y = _s(rowStartY + i * rowHeight + 1);
        this.driverOdds[i].x = _s(rowShiftX + this.shiftXByOffset(184, rowStartY + i * rowHeight));
      }

      this.driverNumberFirstText[i].y = _s(rowStartY + i * rowHeight - 18);
      this.driverNumberFirstText[i].x = _s(rowShiftX + this.shiftXByOffset(43, rowStartY + i * rowHeight));
      this.driverNumberSecondText[i].y = _s(rowStartY + i * rowHeight - 18);
      this.driverNumberSecondText[i].x = _s(rowShiftX + this.shiftXByOffset(90, rowStartY + i * rowHeight));

      this.driverNumberfirstLine1[i].y = _s(rowStartY + 6 + i * rowHeight - rowHeight / 2);
      this.driverNumberfirstLine1[i].x = _s(rowShiftX + this.shiftXByOffset(36, rowStartY + 6 + i * rowHeight - rowHeight / 2));
      this.driverNumberfirstLine1[i].width = _s(10);
      this.driverNumberfirstLine1[i].height = _s(32);

      this.driverNumberfirstLine2[i].y = _s(rowStartY + 6 + i * rowHeight - rowHeight / 2);
      this.driverNumberfirstLine2[i].x = _s(rowShiftX + this.shiftXByOffset(85, rowStartY + 6 + i * rowHeight - rowHeight / 2));
      this.driverNumberfirstLine2[i].width = _s(10);
      this.driverNumberfirstLine2[i].height = _s(32);
    }
  }

  public update(dt: number) {
    super.update(dt);

    const t = Logic.getVideoTime();

    const currentAnim = !Array.isArray(this.anim) ? this.anim : Logic.getAnim(t, this.anim, this);

    if (!currentAnim) return;

    if (Array.isArray(this.anim)) {
      if (t > currentAnim.startTime + currentAnim.duration) {
        this.visible = false;
        return;
      }
    }

    this.visible = true;

    let driverIndex = Util.clamp(Math.floor((t - currentAnim.startTime) / (currentAnim.duration + currentAnim.pause)), 0, this.racerCount - 1);
    let driverAnim = {
      startTime: currentAnim.startTime + driverIndex * (currentAnim.duration + currentAnim.pause),
      duration: currentAnim.duration + (driverIndex >= this.racerCount - 1 ? currentAnim.addLast : 0)
    };
    if (Array.isArray(this.anim)) {
      driverIndex = this.anim.indexOf(currentAnim);
      //driverIndex = Util.clamp(Math.floor((t - this.anim[0].startTime) / (this.anim[0].duration + currentAnim.pause)), 0, this.racerCount - 1);
      driverAnim = currentAnim; // { startTime: currentAnim.startTime + driverIndex * (currentAnim.duration + currentAnim.pause), duration: currentAnim.duration + ((driverIndex >= this.racerCount - 1) ? currentAnim.addLast : 0)};
    } else {
      // if (driverIndex === this.racerCount -2 && this.anim.addSecondToLast)
      //   driverAnim.duration += this.anim.addSecondToLast;
      if (driverIndex >= this.racerCount - 1) driverAnim.duration += currentAnim.addLast;
    }

    const anim = Logic.getAnim(t, [driverAnim], this);
    if (!anim) return;

    if (driverIndex !== this.currentDriverIndex) {
      if (this.updateDriverTexts(driverIndex)) this.currentDriverIndex = driverIndex;
    }

    {
      const fif = t - anim.startTime;
      const fadeOutTime = currentAnim.pause * 0.5; // TODO: nicht überall 0!
      const baseFactor = fif < anim.duration - fadeOutTime ? fif : (anim.duration - fif) / fadeOutTime;
      this.setDebugFade(baseFactor);

      this.driverName.alpha = baseFactor;
      this.driverTitle.alpha = baseFactor;

      const lineOffset = 0.05;
      const rowOffset = 0.05;
      const startOffset = 0.2;
      this.driverNumberMain.alpha = baseFactor - startOffset - lineOffset;
      this.oddsNumberMain.alpha = baseFactor - startOffset - lineOffset + rowOffset;
      for (let i = 0; i < this.racerCount; i++) {
        this.driverNumberCol1[i].alpha = baseFactor - (startOffset + lineOffset + i * lineOffset);
        this.driverNumberCol2[i].alpha = baseFactor - (startOffset + lineOffset + i * lineOffset + rowOffset);
        this.driverOdds[i].alpha = baseFactor - (startOffset + lineOffset + i * lineOffset + rowOffset * 2);
        this.driverNumberfirstLine1[i].alpha = 1;
        this.driverNumberfirstLine1[i].alpha = baseFactor - (startOffset + lineOffset + i * lineOffset);
        this.driverNumberfirstLine2[i].alpha = baseFactor - (startOffset + lineOffset + i * lineOffset + rowOffset);

        this.driverNumberFirstText[i].alpha = baseFactor - (startOffset + lineOffset + i * lineOffset);
        this.driverNumberSecondText[i].alpha = baseFactor - (startOffset + lineOffset + i * lineOffset + rowOffset);
      }
    }
  }
}

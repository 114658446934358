import { Group } from "../../Graphics/Group";
import { BottomBarItemKart } from "./BottomBarItemKart";
import { _s } from "../../Logic/Logic";
import { IDriver, IColors } from "../../Logic/LogicDefinitions";
import { GameLength } from "../../common/Definitions";

export class BottomBarKart extends Group {
  private racerCount: number;
  private items: BottomBarItemKart[] = [];

  public constructor(racerCount: number, gameLength: GameLength, language: string) {
    super();
    this.racerCount = racerCount;
    for (let i = 0; i < racerCount; i++) {
      const bbi = new BottomBarItemKart(i, gameLength, language);
      bbi.position.x = _s(6 + i * 254);
      bbi.position.y = _s(682);
      bbi.width = _s(254);
      bbi.height = _s(25);
      this.items.push(bbi);
      this.add(bbi);
    }
  }

  public fill(drivers: IDriver[], colors: IColors, withBonus: boolean) {
    for (let i = 0; i < this.racerCount; i++) this.items[i].fill(drivers[i], colors, withBonus);
  }

  public update(dt: number) {
    super.update(dt);
  }
}
